import React from 'react';
import {
    Box,
    Chip,
    Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { getRoutePath } from '../utils';

const FilterChip = ({ filterQ, setFilterQ }) => {
    const params = useParams();
    const history = useHistory();

    return (
        <>
            {filterQ?.length
            && (
                <Box mb={2}>
                    <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                        Búsqueda de texto
                    </Typography>
                    <Chip
                        label={filterQ[0].toUpperCase() + filterQ.slice(1)}
                        style={{
                            backgroundColor: '#DBDBD5',
                            color: '#727272',
                            fontSize: '16px',
                            marginTop: '20px',
                            fontWeight: '300',
                            maxWidth: '80%',
                        }}
                        onDelete={() => { history.push(getRoutePath(params)); setFilterQ(null); }}
                    />
                </Box>
            )}
        </>
    );
};

export default FilterChip;
