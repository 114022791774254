import React, { useContext } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UserContext } from '../../../contexts';
import cartHelper from '../../../helpers/cartHelper';

import AppliedCouponCode from './AppliedCouponCode';
import { useCouponStyles } from './useStyles';

const TotalWithCoupon = ({
    shippingCost,
    loadingTotalPrice = false,
    selectedPaymentMode = null,
}) => {
    const classes = useCouponStyles();
    const [state] = useContext(UserContext);

    const renderSubTotalPrice = () => cartHelper.getFormattedSubTotalPrice(
        state.user?.cart,
        selectedPaymentMode,
    );

    const renderTotalPrice = () => cartHelper.getFormattedTotalPrice(
        state.user?.cart,
        selectedPaymentMode,
        shippingCost,
    );

    return (
        <>
            <Box className={classes.totalSubItemContainer}>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Subtotal
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice
                        ? <CircularProgress color="#000" />
                        : (
                            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                {renderSubTotalPrice()}
                            </Typography>
                        )}
                </Box>
            </Box>
            <Divider className={classes.couponDivider} />

            <AppliedCouponCode selectedPaymentMode={selectedPaymentMode} />
            <Divider className={classes.couponDivider} />

            <Box className={classes.totalSubItemContainer}>
                <Typography variant="h6">
                    Total
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice
                        ? <CircularProgress color="#000" />
                        : (
                            <Typography variant="h3">
                                {renderTotalPrice()}
                            </Typography>
                        )}
                </Box>
            </Box>
        </>
    );
};

export default TotalWithCoupon;
