import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import jsonwebtoken from 'jsonwebtoken';
import { useSnackbar } from 'notistack';
import API from '../../api/API';
import CustomDivider from '../components/CustomDivider';
import GoogleLoginButton from './GoogleLoginButton';
import history from '../../helpers/history';
import storageHelper from '../../helpers/storageHelper';
import { UserContext } from '../../contexts';
import handler from '../../helpers/handler';
import userConstants from '../../contexts/User/userConstants';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles(() => ({
    register: {
        margin: 'auto',
        maxWidth: 302,
    },
    emailInput: {
        marginTop: '31px',
    },
    closeIcon: {
        color: '#727272',
    },
}));

const RegisterModal = ({ open, setOpen, openLoginModal }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [state, dispatch] = useContext(UserContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        if (password !== passwordConfirmation) {
            enqueueSnackbar('Las contraseñas no coinciden', { variant: 'warning' });
            return;
        }
        const newUser = {
            email,
            password,
        };

        const cart = storageHelper.getUserCart();
        API.auth.register(newUser)
            .then((response) => {
                const { token } = response.data;
                const decodedToken = jsonwebtoken.decode(token);
                const user = { email, role: decodedToken.role };
                dispatch({ type: userConstants.SET_USER, user });
                storageHelper.setUser(user);
                if (cart?.id) API.carts.updateCart(cart);

                enqueueSnackbar(`Registro realizado con éxito. Bienvenido, ${user.email}`, { variant: 'success' });
                setEmail('');
                setPassword('');
                setPasswordConfirmation('');
                handleClose();
            })
            .catch((error) => {
                if (error.response?.status === 400) enqueueSnackbar('Tu contraseña no cumple con los requisitos mínimos.', { variant: 'warning' });
                else if (error.response?.status === 403) enqueueSnackbar('Ya existe un usuario con este email.', { variant: 'error' });
                else {
                    const customErrorMessage = 'Hubo un problema intentando registrar tu cuenta. Por favor intentá nuevamente en unos minutos';
                    handler.handleError({
                        error, userContextDispatch: dispatch, enqueueSnackbar, customErrorMessage,
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangePassword = (event) => setPassword(event.target.value);
    const handleChangePasswordConfirmation = (event) => setPasswordConfirmation(event.target.value);

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        storageHelper.waitGoogleLogin();
        history.push(`${process.env.REACT_APP_API_URL}/auth/google`);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ square: true }}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent>
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">

                        <Button
                            onClick={handleClose}
                            className={classes.closeIcon}
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h4">
                            Crear cuenta
                        </Typography>
                    </Box>

                    <Box textAlign="center">
                        <Box className={classes.register}>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="username"
                                        value={email}
                                        InputProps={{
                                            className: classes.emailInput,
                                        }}
                                        onChange={handleChangeEmail}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Contraseña"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="new-password"
                                        onChange={handleChangePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword
                                                            ? <VisibilityIcon />
                                                            : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                        }}
                                    />
                                    <Box mt={1} display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start">
                                        <Typography variant="caption" align="left" color="textSecondary">La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico </Typography>
                                    </Box>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Repetir contraseña"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="new-password"
                                        value={passwordConfirmation}
                                        onChange={handleChangePasswordConfirmation}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword
                                                            ? <VisibilityIcon />
                                                            : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                        }}
                                    />
                                </FormControl>

                                <Box mt={4} mb={2}>
                                    <FormControl fullWidth>
                                        <LoadingButton variant="contained" type="submit">CREAR CUENTA</LoadingButton>
                                    </FormControl>
                                </Box>
                            </form>

                            <CustomDivider />
                            <GoogleLoginButton
                                disabled={loading}
                                onClick={handleGoogleLogin}
                            />
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle1" align="center">
                                {'¿Ya tenés cuenta? '}
                                <Button variant="text" onClick={openLoginModal}>
                                    INICIAR SESIÓN
                                </Button>
                            </Typography>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </div>

    );
};

export default RegisterModal;
