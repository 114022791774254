import React from 'react';
import {
    Box,
    Hidden,
    Button,
    Typography,
    Container,
} from '@material-ui/core';
import filterIcon from '../filter_icon.svg';

import { useMobileFiltersDropdownButtonStyles } from './styles';

const MobileFiltersDropdownButton = ({ onClick }) => {
    const classes = useMobileFiltersDropdownButtonStyles();
    return (
        <Hidden mdUp>
            <Box className={classes.mobileFilterContainer}>
                <Container>
                    <Button onClick={onClick}>
                        <Box display="flex" alignItems="center" my={1}>
                            <img
                                alt="filter icon"
                                src={filterIcon}
                                className={classes.filterIcon}
                            />
                            <Typography className={classes.filterLabel}>
                                VER FILTROS
                            </Typography>
                        </Box>
                    </Button>
                </Container>
            </Box>
        </Hidden>
    );
};

export default MobileFiltersDropdownButton;
