import React, {
    useContext, useEffect, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Typography, Button,
} from '@material-ui/core';
import {
    KeyboardArrowDown, KeyboardArrowUp,
} from '@material-ui/icons';
import colors from '../../../theme/colors';
import helper from '../../../helpers/cartHelper';
import Step from '../../components/Step';
import { ProductsContext } from '../../../contexts/Products/context';
import StepButton from '../../components/StepButton';
import ModalOrderProductList from '../../user/ModalOrderProductList';

const useStyles = makeStyles(() => ({
    componentsButton: {
        width: '222px',
        borderBottom: '1px solid #404040',
        justifyContent: 'flex-start',
        paddingLeft: 0,
        color: '#F8F9FB',
        textTransform: 'none',
    },
    hideButtonIcon: {
        marginLeft: 'auto',
    },
    steps: {
        background: colors.black,
        color: colors.white,
        width: 300,
    },
    detailsButton: {
        color: '#F8E837',
        paddingLeft: 0,
    },
    headerMobile: {
        maxWidth: '100%',
        marginTop: '0',
        display: 'flex',
        marginLeft: 0,
        flexDirection: 'column',
        backgroundColor: '#1C1C1C',
        color: '#F8F9FB',
    },
    titleMobile: {
        margin: '10px 0',
        fontSize: 18,
        lineHeight: '22px',
    },
    containerDetailsMobile: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    totalTitleMobile: {
        marginRight: '7px',
        fontWeight: 300,
    },
    containerStepper: {
        overflowX: 'hidden',
        display: 'flex',
        width: '90%',
        margin: '20px auto 0 auto',
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step0: {
        position: 'relative',
        top: 8,
        right: 206,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step1: {
        position: 'relative',
        top: 8,
        right: 190,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step2: {
        position: 'relative',
        top: 82,
        right: 217,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step3: {
        position: 'relative',
        top: 82,
        right: 371,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step4: {
        position: 'relative',
        top: 157,
        right: 481,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step5: {
        position: 'relative',
        top: 157,
        right: 454,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step6: {
        position: 'relative',
        top: 232,
        right: 493,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step7: {
        position: 'relative',
        top: 232,
        right: 666,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step8: {
        position: 'relative',
        top: 308,
        right: 775,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    step9: {
        position: 'relative',
        top: 308,
        right: 749,
    },
}));

const Roadmap = ({
    handleShowComponents,
    showComponents,
    actualStep,
    steps,
    handleClick,
    buildSpecs,
    setActualStep,
    mobile,
    myRef,
    lastStep,
}) => {
    const classes = useStyles();
    const [modalOrderProductListOpen, setModalOrderProductListOpen] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const { productsDispatch } = useContext(ProductsContext);

    const countTotalPrice = () => {
        let price = 0;
        if (buildSpecs) {
            Object.values(buildSpecs).forEach((spec) => {
                if (spec && spec.price) {
                    price += (
                        (parseInt(spec.quantity, 10) || 1)
                        * parseInt(spec.price.special.ARS, 10));
                }
                if (Array.isArray(spec)) {
                    spec.forEach((specFromArray) => {
                        if (specFromArray.price) {
                            price += (
                                (parseInt(specFromArray.quantity, 10) || 1)
                            * parseInt(specFromArray.price.special.ARS, 10));
                        }
                    });
                }
            });
        }
        setTotalPrice(price);
    };

    useEffect(() => {
        countTotalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildSpecs]);

    const [skippedStepsForURLRoadmapSVG, setSkippedStepsForURLRoadmapSVG] = useState('');
    useEffect(() => {
        if (!buildSpecs.gpu && !buildSpecs.case_fan && lastStep) setSkippedStepsForURLRoadmapSVG('_nogpu_nocooling');
        else if (buildSpecs.gpu && !buildSpecs.case_fan && lastStep) setSkippedStepsForURLRoadmapSVG('_nocooling');
        else if (!buildSpecs.gpu && actualStep > 5) setSkippedStepsForURLRoadmapSVG('_nogpu');
        else setSkippedStepsForURLRoadmapSVG('');
    }, [actualStep, buildSpecs, lastStep]);

    const renderStepButtons = steps.map((step, index) => {
        if (step.name !== 'peripherals') {
            return (
                <Box key={step.name} className={classes[`step${index}`]}>
                    <StepButton
                        step={step}
                        steps={steps}
                        buildSpecs={buildSpecs}
                        actualStep={actualStep}
                        index={index}
                        handleClick={handleClick}
                        classes={classes}
                        lastStep={lastStep}
                    />
                </Box>
            );
        }
        return '';
    });

    const toggleModalOrderProductList = () => {
        setModalOrderProductListOpen(!modalOrderProductListOpen);
    };

    return (
        !mobile
            ? (
                <Box className={classes.steps} px={5}>
                    <Box pt={3} pb={3}>
                        <Typography variant="h1" style={{ fontSize: 18, lineHeight: '22px' }}>
                            Pro Builder
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography style={{ fontWeight: 300, textAlign: 'left' }} variant="h6">
                            Total acumulado:
                            {' '}
                        </Typography>
                        <Typography variant="h4">
                            {helper.formatPrice(totalPrice)}
                        </Typography>
                    </Box>
                    <Box mb={1} display="flex">
                        <Button
                            variant="text"
                            onClick={toggleModalOrderProductList}
                            className={classes.detailsButton}
                        >
                            Ver detalle
                        </Button>
                        <ModalOrderProductList
                            openModal={modalOrderProductListOpen}
                            toggleModal={toggleModalOrderProductList}
                            buildSpecs={buildSpecs}
                            productsDispatch={productsDispatch}
                            setActualStep={setActualStep}
                            totalPrice={totalPrice}
                        />
                    </Box>
                    <Box pb={4} display="flex">
                        <Button
                            className={classes.componentsButton}
                            onClick={handleShowComponents}
                            classes={{ iconSizeMedium: classes.hideButtonIcon }}
                            endIcon={showComponents
                                ? <KeyboardArrowUp fontSize="large" style={{ color: '#727272' }} />
                                : <KeyboardArrowDown fontSize="large" style={{ color: '#727272' }} />}
                        >
                            Componentes
                        </Button>
                    </Box>
                    <Box pb={4} display={showComponents ? 'flex' : 'none'}>
                        <img src={`/images/pro-builder/step${actualStep}${skippedStepsForURLRoadmapSVG}.svg`} height={347} alt={`Paso actual: ${actualStep}`} />
                        {renderStepButtons}
                    </Box>
                </Box>
            )
            : (
                <Box className={classes.headerMobile}>
                    <Typography variant="h1" className={classes.titleMobile}>Pro Builder</Typography>
                    <Box display="flex" className={classes.containerDetailsMobile}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography className={classes.totalTitleMobile} variant="h6">
                                Total acumulado:
                                {' '}
                            </Typography>
                            <Typography variant="h4">
                                {helper.formatPrice(totalPrice)}
                            </Typography>
                        </Box>
                        <Button
                            variant="text"
                            onClick={toggleModalOrderProductList}
                            className={classes.detailsButton}
                        >
                            Ver detalle
                        </Button>
                        <ModalOrderProductList
                            openModal={modalOrderProductListOpen}
                            toggleModal={toggleModalOrderProductList}
                            buildSpecs={buildSpecs}
                            productsDispatch={productsDispatch}
                            setActualStep={setActualStep}
                            totalPrice={totalPrice}
                        />
                    </Box>
                    <Box className={classes.containerStepper}>
                        {steps.map((step, index) => {
                            if (index < 10) {
                                return (
                                    <Step
                                        key={step.name}
                                        step={step}
                                        index={index}
                                        actualStep={actualStep}
                                        isFinished={buildSpecs[step.name] !== null}
                                        myRef={myRef}
                                        isLast={index === 9}
                                    />
                                );
                            }
                            return (<Box display="none" />);
                        })}
                    </Box>
                </Box>
            )
    );
};

export default Roadmap;
