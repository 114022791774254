import React from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import InfoIcon from '@material-ui/icons/Info';
import colors from '../../theme/colors';

const useStyles = makeStyles(() => ({
    infoPaymentContainer: {
        padding: '13px 8px 0px 16px',
        width: '200px',
    },
    titleInfoPayment: {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '15px',
        color: colors.blackGrey,
    },
    iconMoreInformation: {
        marginLeft: '-6px',
        height: 14,
        width: 14,
    },
    infoPayment: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: colors.blackGrey,
    },

}));
const SpecialPricePopover = () => {
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-Popover',
    });

    return (
        <>
            <IconButton
                aria-label="moreInformation"
                color="inherit"
                style={{ color: colors.blackGrey }}
                {...bindHover(popupState)}
            >
                <InfoIcon
                    className={classes.iconMoreInformation}
                />
            </IconButton>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Box className={classes.infoPaymentContainer}>
                    <Typography variant="subtitle2" className={classes.titleInfoPayment}>
                        Precio válido para pagos con
                    </Typography>
                    <ul style={{ paddingLeft: '16px' }}>
                        <li className={classes.infoPayment}>DÉBITO</li>
                        <li className={classes.infoPayment}>CRÉDITO EN 1 PAGO</li>
                        <li className={classes.infoPayment}>TRANSFERENCIA</li>
                        <li className={classes.infoPayment}>DEPÓSITO BANCARIO</li>
                    </ul>
                </Box>
            </Popover>
        </>
    );
};

export default SpecialPricePopover;
