import React from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ItemWithType = ({ handleClear, type }) => (
    <Box display="flex" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center" mb={1} width="auto">
            <img
                src={type.icon}
                alt={`${type.title}`}
                style={{
                    marginRight: 20,
                    width: '51px',
                    height: '46px',
                }}
            />
            <Typography variant="h1" style={{ fontSize: '18px', lineHeight: '22px' }}>
                {type.title}
            </Typography>
            <IconButton
                style={{
                    marginRight: '10px',
                    height: '30px',
                    width: '30px',
                    marginLeft: '10px',
                }}
                onClick={handleClear}
            >
                <ClearIcon />
            </IconButton>
        </Box>
    </Box>
);

export default ItemWithType;
