import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';

import colors from '../../theme/colors';

const NotFoundImage = '/images/notFound.svg';

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        marginTop: '32px',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'start',
        },
    },
    container: {
        alignItems: 'center',
        backgroundColor: colors.black,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100dvh',
        padding: '0px 16px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    commonSubContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    firstSubContainer: {
        display: 'flex',
        height: '173px',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            height: '292px',
        },
    },
    offersButton: {
        color: colors.yellow,
        height: '34px',
        '&:hover': {
            backgroundColor: colors.yellow,
            color: colors.black,
        },
    },
    secondSubContainer: {
        marginTop: '16px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        },
    },
    subTitle: {
        color: colors.white,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19.2px',
        marginTop: '16px',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'left',
            width: '70%',
        },
    },
    title: {
        color: colors.yellow,
        fontSize: '25px',
        fontWeight: 850,
        lineHeight: '30px',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            fontSize: '38px',
            lineHeight: '45.6px',
            textAlign: 'left',
            width: '70%',
        },
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        history.push(path);
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Box className={classes.container}>
                <Box className={[classes.commonSubContainer, classes.firstSubContainer]}>
                    <img
                        alt="Ilustración que indica página no encontrada"
                        loading="lazy"
                        src={NotFoundImage}
                        style={{ maxWidth: '100%', height: '100%' }}
                    />
                </Box>
                <Box className={[classes.commonSubContainer, classes.secondSubContainer]}>
                    <Typography className={classes.title} variant="h1">
                        ¡OOPS! No encontramos la página que buscás
                    </Typography>
                    <Typography className={classes.subTitle} variant="h2">
                        Es posible que la URL se haya modificado,
                        eliminado o se haya escrito incorrectamente.
                    </Typography>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            onClick={() => handleNavigation('/')}
                            variant="contained"
                        >
                            Ir a la página de inicio
                        </Button>
                        <Button
                            className={classes.offersButton}
                            onClick={() => handleNavigation('/ofertas')}
                            variant="outlined"
                        >
                            Ver ofertas 🔥
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default NotFound;
