import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Typography,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';
import ProBuilderProductCard from '../../product/ProBuilderProductCard';
import productHelper from '../../../helpers/productHelper';
import ProductsSkeleton from '../../product/ProductsSkeleton';
import NoResultsMessage from '../../components/NoResultsMessage';
import useWindowDimensions from '../../../helpers/useWindowsDimentions';
import API from '../../../api/API';
import { UserContext } from '../../../contexts';
import handler from '../../../helpers/handler';
import proBuildHelper from '../../../helpers/proBuildHelper';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingLeft: '24px',
        '@media (max-width: 960px)': {
            padding: 0,

        },
    },
    formControl: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 120,
    },
    sortSelect: {
        marginTop: 0,
        fontSize: 12,
        fontWeight: 'bold',
    },
    selectInput: {
        marginLeft: 4,
        paddingBottom: 4,
    },
    productsGrid: {
        paddingTop: 10,
    },
}));

const getProductsPerPage = ({ width, hasDefaultProduct }) => {
    let productsPerPage = 6;
    if (width < 960) productsPerPage = 4;
    if (width < 1280) productsPerPage = 6;
    if (width < 1920) productsPerPage = 8;

    if (hasDefaultProduct) productsPerPage -= 1;
    return productsPerPage;
};

const ProBuilderProductList = ({
    buildProduct,
    selectProduct,
    deselectProduct,
    multiSelect = false,
    validateQuantity,
    peripherals = false,
    defaultProduct,
    productType,
    buildSpecs,
}) => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const [state, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [sort, setSort] = useState('popularity');
    const [productList, setProductList] = useState();
    const currentPage = useRef();
    const totalPages = useRef();
    const [loading, setLoading] = useState(false);

    const updateProductQuantity = (productId, quantity) => {
        const productIndex = productList.findIndex(({ id }) => id === productId);
        const newProductList = [...productList];
        newProductList[productIndex] = { ...newProductList[productIndex], quantity };
        setProductList(newProductList);
    };

    const isDefaultProduct = (product) => product.default;

    const isProductSelected = (product) => {
        if (multiSelect && buildProduct && buildProduct.length > 0) {
            const productIndex = buildProduct.findIndex(({ id }) => id === product.id);
            return productIndex !== -1;
        }
        if (!buildProduct) return isDefaultProduct(product);
        return (buildProduct && (buildProduct.id === product.id));
    };

    const getProductQuantity = (product) => {
        if (!multiSelect) return 1;
        const productIndex = buildProduct.findIndex(({ id }) => id === product.id);
        return productIndex !== -1 ? buildProduct[productIndex].quantity : (product.quantity || 1);
    };

    const getProducts = () => {
        const nextPage = currentPage.current ? currentPage.current + 1 : 1;
        setLoading(true);
        const buildFilter = proBuildHelper.createBuildFilter(buildSpecs, peripherals ? 'peripherals' : productType);
        API.products.compatibles({
            requestedTypeList: productType,
            brand: buildSpecs.cpuBrand,
            build: buildFilter,
            page: nextPage,
            perPage: getProductsPerPage({ width, hasDefaultProduct: !!defaultProduct }),
            sort: productHelper.createSortObject(sort),
        }).then(({ data: { data, total } }) => {
            if (defaultProduct && nextPage === 1) data.unshift(defaultProduct);

            setProductList((prevProducts) => [...(prevProducts || []), ...data]);
            totalPages.current = Math.ceil(
                total / getProductsPerPage({ width, hasDefaultProduct: !!defaultProduct }),
            );
            currentPage.current = nextPage;
            setLoading(false);
        }).catch(
            (error) => handler.handleError({
                error, userContextDispatch: dispatch, enqueueSnackbar,
            }),
        );
    };

    useEffect(() => {
        setProductList(undefined);
        currentPage.current = undefined;
        totalPages.current = undefined;
        getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productType, sort]);

    const Products = () => productList.map((product) => (
        <Grid key={product.id} item xs={6} sm={4} lg={3} xl={2}>
            <ProBuilderProductCard
                key={product.id}
                product={product}
                checked={isProductSelected(product)}
                productQuantity={getProductQuantity(product)}
                selectProduct={selectProduct}
                updateProductQuantity={updateProductQuantity}
                deselectProduct={deselectProduct}
                multiSelect={multiSelect}
                validateQuantity={validateQuantity}
                buildProduct={buildProduct}
            />
        </Grid>
    ));

    return (
        <Box className={classes.mainContainer} display="flex" flexDirection="column">
            {productList && productList.length > 0 && (
                <>
                    <Box display="flex" justifyContent="flex-end" mb={2}>
                        <FormControl className={classes.formControl}>
                            <Typography variant="subtitle2">Ordenar por: </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                autoWidth
                                onChange={(e) => setSort(e.target.value)}
                                inputProps={{
                                    className: classes.selectInput,
                                }}
                                disableUnderline
                                className={classes.sortSelect}
                            >
                                <MenuItem value={productHelper.SORT_VALUES.popularity}>
                                    Los más populares
                                </MenuItem>
                                <MenuItem value={productHelper.SORT_VALUES.higherPrice}>
                                    Mayor precio
                                </MenuItem>
                                <MenuItem value={productHelper.SORT_VALUES.lowerPrice}>
                                    Menor precio
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <InfiniteScroll
                        dataLength={productList?.length}
                        next={getProducts}
                        hasMore={currentPage.current < totalPages.current}
                        style={{ overflow: 'inherit' }}
                        scrollThreshold="200px"
                    >
                        <Grid container className={classes.productsGrid} spacing={2}>
                            <Products />
                        </Grid>
                    </InfiniteScroll>
                </>
            )}
            {(!productList || loading) && (
                <Box pt={2}>
                    <ProductsSkeleton
                        showSortSkeleton={!(currentPage?.current >= 1)}
                        marginLeft={0}
                    />
                </Box>
            )}
            {productList && productList.length === 0 && !loading && (
                <NoResultsMessage from="proBuilder" />
            )}
        </Box>
    );
};

export default ProBuilderProductList;
