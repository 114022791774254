import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox, Dialog, DialogContent, Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import jsonwebtoken from 'jsonwebtoken';
import { useSnackbar } from 'notistack';
import API from '../../api/API';
import CustomDivider from '../components/CustomDivider';
import GoogleLoginButton from './GoogleLoginButton';
import history from '../../helpers/history';
import storageHelper from '../../helpers/storageHelper';
import { UserContext } from '../../contexts';
import handler from '../../helpers/handler';
import userConstants from '../../contexts/User/userConstants';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles(() => ({
    register: {
        margin: 'auto',
        maxWidth: 302,
    },
    closeIcon: {
        color: '#727272',
    },
}));

const RecoverPasswordModal = ({ token, open, setOpen }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [state, dispatch] = useContext(UserContext);

    const handleClose = () => {
        setOpen(false);
        history.push('/');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== passwordConfirmation) {
            enqueueSnackbar('Las contraseñas no coinciden', { variant: 'warning' });
            return;
        }
        setLoading(true);
        API.auth.recoverPassword({ token, newPassword: password })
            .then(() => {
                handleClose();
                enqueueSnackbar('Tu contraseña fue cambiada correctamente. Ya podés iniciar sesión.', { variant: 'success' });
                history.push('/');
            })
            .catch((error) => {
                if (error.response?.status === 400) enqueueSnackbar('Tu contraseña no cumple con los requisitos mínimos.', { variant: 'warning' });
                else enqueueSnackbar('Hubo un problema intentando recuperar tu contraseña. Por favor iniciá el proceso de recuperación nuevamente', { variant: 'error' });
                return handler.handleError({
                    error, userContextDispatch: dispatch, enqueueSnackbar,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleChangePassword = (event) => setPassword(event.target.value);
    const handleChangePasswordConfirmation = (event) => setPasswordConfirmation(event.target.value);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ square: true }}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent>
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">

                        <Button
                            onClick={handleClose}
                            className={classes.closeIcon}
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h4">
                            Recuperar contraseña
                        </Typography>
                    </Box>

                    <Box textAlign="center">
                        <Box className={classes.register}>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Nueva contraseña"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="new-password"
                                        onChange={handleChangePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword
                                                            ? <VisibilityIcon />
                                                            : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                        }}
                                    />
                                    <Box mt={1} display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start">
                                        <Typography variant="caption" align="left" color="textSecondary">La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico </Typography>
                                    </Box>

                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Repetir nueva contraseña"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="new-password"
                                        value={passwordConfirmation}
                                        onChange={handleChangePasswordConfirmation}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword
                                                            ? <VisibilityIcon />
                                                            : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),

                                        }}
                                    />
                                </FormControl>

                                <Box mt={4} mb={2}>
                                    <FormControl fullWidth>
                                        <LoadingButton loading={loading} variant="contained" type="submit">CAMBIAR CONTRASEÑA</LoadingButton>
                                    </FormControl>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>

    );
};

export default RecoverPasswordModal;
