import React, { useEffect } from 'react';
import { scrollIntoView } from 'seamless-scroll-polyfill/lib';

import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(() => ({
    containerStep: {
        display: 'flex',
        fontFamily: 'Frutiger!important',
    },
    step: {
        width: '80px',
        textAlign: 'center',
    },
    stepButton: {
        border: 'none',
        padding: '10px 15px',
    },
    spacer: {
        border: 'none',
        marginTop: '15px',
        width: '50px',
        height: '1px',
    },
    lastSpacer: {
        marginTop: '20px',
        display: 'none',
    },
}));

const Step = ({
    step, index: stepIndex, actualStep: actualStepIndex, myRef, isFinished = true,
    showName = false, isLast = false, onClick = null,
    selectedColor = colors.white,
    normalColor = '#404040',
    selectedSecondaryColor = colors.yellow,
}) => {
    const classes = useStyles();
    const scroll = () => {
        scrollIntoView(myRef.current, {
            behavior: 'smooth',
            block: 'nearest',
        });
    };
    useEffect(() => {
        if (myRef.current) {
            scroll();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualStepIndex]);

    function handleClick() {
        if (onClick) onClick(stepIndex);
    }

    return (
        <div className={classes.containerStep}>
            <div className={classes.step} ref={actualStepIndex === stepIndex ? myRef : null}>
                <button
                    onClick={handleClick}
                    type="button"
                    style={{
                        backgroundColor:
                            (stepIndex > actualStepIndex)
                            || (!isFinished && stepIndex !== actualStepIndex)
                                ? normalColor
                                : selectedSecondaryColor,
                    }}
                    className={classes.stepButton}
                >
                    <div style={{ color: colors.black }}>{(isFinished && stepIndex < actualStepIndex) ? '✓' : stepIndex + 1}</div>
                </button>
                <p color={stepIndex === actualStepIndex ? selectedColor : normalColor}>
                    { showName ? step.name : step.title }
                </p>
            </div>
            <hr
                className={!isLast ? classes.spacer : classes.lastSpacer}
                style={{
                    backgroundColor:
                        stepIndex >= actualStepIndex ? normalColor : selectedSecondaryColor,
                }}
            />
        </div>
    );
};

export default Step;
