import React from 'react';
import { Box, Chip } from '@material-ui/core';

const ChipSpecs = ({ checked, handleDeleteChip }) => (
    <Box mt={2} display="flex" flexWrap="wrap" justifyContent="between">
        {Object.keys(checked).map((selectedSpec) => (
            <Box key={selectedSpec}>
                {
                    Boolean(checked[selectedSpec])
                    && (
                        <Box mr={1} mb={1}>
                            <Chip
                                label={selectedSpec[0].toUpperCase() + selectedSpec.slice(1)}
                                style={{
                                    backgroundColor: '#DBDBD5',
                                    color: '#727272',
                                    fontSize: '16px',
                                    fontWeight: '300',
                                }}
                                checked={selectedSpec}
                                onDelete={(e) => handleDeleteChip(e, selectedSpec)}
                            />
                        </Box>
                    )
                }
            </Box>
        ))}
    </Box>
);

export default ChipSpecs;
