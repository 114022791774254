import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import gameCheckbox from '../images/gameCheckbox.svg';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: '16px',
        marginLeft: '16px',
        marginBottom: '22px',
    },
    unchecked: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: 'transparent',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },

    checked: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        position: 'relative',
        filter: 'drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.15))',
        '&::after': {
            content: `url(${gameCheckbox})`,
            width: 25,
            height: 26,
            display: 'block',
            position: 'absolute',
            right: -12,
            top: -13,
        },
    },

    image: {
        height: 128,
        width: 100,
        objectFit: 'cover',
    },
}));

const ImageCheckbox = ({
    checked, image, title, onChange, defaultImage, ...props
}) => {
    const [isSelected, setSelected] = useState(checked);

    const classes = useStyles();

    const handleChange = () => {
        const newSelectedValue = !isSelected;
        setSelected(newSelectedValue);
        onChange(newSelectedValue);
    };

    return (
        <ButtonBase
            className={`${classes.button} ${isSelected ? classes.checked : classes.unchecked}`}
            onClick={handleChange}
            {...props}
        >
            <img
                className={classes.image}
                src={image ?? defaultImage}
                alt={title}
            />
        </ButtonBase>
    );
};

export default ImageCheckbox;
