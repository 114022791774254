import React, { useEffect } from 'react';
import ProBuilderProductList from './ProBuilderProductList';

const BaseProductSelect = ({
    updateBuildSpecs, buildSpecs, validateNextStepAllowed,
    productType, buildProduct, defaultProduct = null, hasDefault = false,
}) => {
    const selectProduct = (product) => updateBuildSpecs({
        [productType]: (hasDefault && product.default) ? null : product,
    });

    const deselectProduct = () => updateBuildSpecs({ [productType]: null });

    const productSpecs = buildSpecs[productType];
    useEffect(() => validateNextStepAllowed(), [validateNextStepAllowed, productSpecs]);

    return (
        <ProBuilderProductList
            buildProduct={buildProduct}
            selectProduct={selectProduct}
            deselectProduct={deselectProduct}
            defaultProduct={defaultProduct}
            productType={productType}
            buildSpecs={buildSpecs}
        />
    );
};

export default BaseProductSelect;
