import React, { useState } from 'react';
import {
    Box, makeStyles, Typography, Hidden,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, ButtonBase,
} from '@material-ui/core';
import moment from 'moment';
import colors from '../../theme/colors';
import cartHelper from '../../helpers/cartHelper';
import ModalOrderProductList from './ModalOrderProductList';

const useStyles = makeStyles(() => ({
    textCurrentBuy: {
        fontWeight: 700,
        fontSize: '16px',
    },
    orderItemLink: {
        fontWeight: 400,
        textDecoration: 'none',
        color: colors.black,
    },
    tableTitle: {
        fontWeight: 300,
        color: colors.blackGrey,
        fontSize: '16px',
    },
    labelRowsPerPage: {
        fontWeight: 300,
        color: colors.blackGrey,
        fontSize: '12px',
    },
    tableItem: {
        fontWeight: 400,
        fontSize: '14px',
        paddingTop: 13,
        paddingBottom: 13,
    },
}));

const CompletedOrders = ({ orders }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalOrderProductListOpen, setModalOrderProductListOpen] = useState(false);
    const toggleModalOrderProductList = () => {
        setModalOrderProductListOpen(!modalOrderProductListOpen);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const ordersCompleted = orders.filter((order) => order.status === 'completed');

    return (
        <>
            {ordersCompleted.length
                ? (
                    <Box pt={3}>
                        <Box display="flex" pb={2}>
                            <Typography className={classes.textCurrentBuy}>
                                Compras finalizadas
                            </Typography>
                        </Box>

                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="none" className={classes.tableTitle}>Nro. de compras</TableCell>
                                        <TableCell className={classes.tableTitle}>Total</TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Productos
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Entrega
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ordersCompleted
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={row.code}>
                                                <TableCell
                                                    className={classes.tableItem}
                                                    style={{ paddingRight: 12 }}
                                                    padding="none"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.code}
                                                </TableCell>
                                                <TableCell className={classes.tableItem}>
                                                    {cartHelper.formatPrice(row.totalPrice)}
                                                </TableCell>
                                                <TableCell className={classes.tableItem}>
                                                    <Typography
                                                        component={ButtonBase}
                                                        onClick={toggleModalOrderProductList}
                                                        variant="subtitle1"
                                                        className={classes.orderItemLink}
                                                    >
                                                        VER DETALLE
                                                    </Typography>
                                                    <ModalOrderProductList
                                                        order={row}
                                                        openModal={modalOrderProductListOpen}
                                                        toggleModal={toggleModalOrderProductList}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.tableItem}>
                                                    {moment(row.statusDates.completed, false).format('DD/MM/YYYY')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Hidden xsDown>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                labelRowsPerPage={(
                                    <Box className={classes.labelRowsPerPage}>
                                        Resultados por página
                                    </Box>
                                )}
                                labelDisplayedRows={(row) => (
                                    <Box flexGrow={1} className={classes.labelRowsPerPage} justifyContent="flex-end">
                                        {row.from}
                                        {' - '}
                                        {row.to}
                                        {' de '}
                                        {row.count}
                                    </Box>
                                )}
                                component="div"
                                count={ordersCompleted.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    style: {
                                        fontWeight: 300,
                                        color: colors.blackGrey,
                                        fontSize: '12px',
                                    },
                                }}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    style: { color: colors.blackGrey },
                                    autoid: 'pagination-button-next-collector',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    style: { color: colors.blackGrey },
                                    autoid: 'pagination-button-previous-collector',
                                }}
                            />
                        </Hidden>
                    </Box>
                )
                : null}
        </>
    );
};

export default CompletedOrders;
