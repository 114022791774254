import React from 'react';
import {
    Box,
    Select,
    MenuItem,
    Typography,
    FormControl,
} from '@material-ui/core';

import { useOrderSelectStyles } from './styles';
import productHelper from '../../../helpers/productHelper';

const OrderSelect = ({ sort, setSort }) => {
    const classes = useOrderSelectStyles();
    return (
        <Box display="flex" justifyContent="flex-end">
            <FormControl className={classes.formControl}>
                <Typography variant="subtitle2" className={classes.orderSelectTitle}>
                    {'Ordenar por: '}
                </Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    autoWidth
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                    inputProps={{
                        className: classes.selectInput,
                    }}
                    disableUnderline
                    className={classes.sortSelect}
                >
                    <MenuItem value={productHelper.SORT_VALUES.popularity}>
                        Los más populares
                    </MenuItem>
                    <MenuItem value={productHelper.SORT_VALUES.higherPrice}>
                        Mayor precio
                    </MenuItem>
                    <MenuItem value={productHelper.SORT_VALUES.lowerPrice}>
                        Menor precio
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default OrderSelect;
