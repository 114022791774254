import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import colors from '../../../../../../../theme/colors';

const useStyles = makeStyles(() => ({
    loadingIcon: {
        color: colors.black,
        marginRight: 5,
    },
}));

const IconToDisplay = ({ loading }) => {
    const classes = useStyles();

    if (loading === 'loading') {
        return <CircularProgress className={classes.loadingIcon} size={15} />;
    }

    if (loading) return <ExpandLess />;

    return <ExpandMore />;
};

export default IconToDisplay;
