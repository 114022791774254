import React from 'react';
import { ProductTypes } from '../../../../helpers/types';
import BaseProductSelect from '../BaseProductSelect';

const CPUSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed,
}) => {
    const validateNextStepAllowed = () => {
        setGoToNextAllowed(buildSpecs.cpu && buildSpecs.cpu.quantity >= 1);
    };

    return (
        <BaseProductSelect
            updateBuildSpecs={updateBuildSpecs}
            buildSpecs={buildSpecs}
            validateNextStepAllowed={validateNextStepAllowed}
            productType={ProductTypes.CPU}
            buildProduct={buildSpecs.cpu}
        />
    );
};

export default CPUSelect;
