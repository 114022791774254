/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';

export const useMercadopago = () => {
    const [loading, setLoading] = useState(true);
    const [mercadopago, setMercadopago] = useState(null);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://sdk.mercadopago.com/js/v2';
        script.async = true;
        script.onload = () => {
            setMercadopago(new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY, {
                locale: 'es-AR',
            }));
            setLoading(false);
        };
        document.body.appendChild(script);
    }, []);

    return {
        loading,
        setLoading,
        mercadopago,
    };
};

export default useMercadopago;
