import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import API from '../../../api/API';
import FilterChip from './FilterChip';
import ChipSpecs from './ChipSpecs';
import { useProductFilterByTypeStyles } from './styles';
import Brands from './Brands';
import TypeSpecs from './TypeSpecs';
import { maxAndMinState, selectedInitialValues } from '../constants';
import ItemWithType from './ItemWithType';

const ProductFilterByType = ({
    route,
    type,
    setPrice,
    price,
    handleSelect,
    setSelectedType,
    filterQ,
    setFilterQ,
    mobile,
    handleChangeCheck,
    checked,
    setChecked,
    handleChangePrice,
    setSelectedPrice,
    selectedPrice,
    handlePriceEntered,
    setShowMobileFilters,
    handleDeleteChip,
}) => {
    const classes = useProductFilterByTypeStyles();
    const history = useHistory();
    const [brandsForFilter, setBrandsForFilter] = useState([]);

    useEffect(() => {
        API.products.brands({ productType: type.type })
            .then((response) => {
                const brands = response.data;
                setBrandsForFilter(brands.data);
            })
            .catch((error) => console.error(error));
    }, [type.type]);

    const handleClear = () => {
        setSelectedPrice(maxAndMinState);
        handleSelect(selectedInitialValues);
        setPrice(maxAndMinState);
        setSelectedType(null);
        setChecked({});
        setShowMobileFilters(false);
        history.push(route);
    };

    const handleCheckBrand = (e) => {
        handleSelect({
            brand: e.target.name,
        });
        handleChangeCheck(e, brandsForFilter);
    };

    const handleCheckTypeSpec = (e, selectedSpec) => {
        handleSelect({
            spec: e.target.name,
            specType: selectedSpec.type,
        });
        handleChangeCheck(e, selectedSpec);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && (selectedPrice.min !== null || selectedPrice.max !== null)) {
            handlePriceEntered();
        }
    };

    return (
        <>
            <ItemWithType handleClear={handleClear} type={type} />
            {!mobile && (
                <ChipSpecs
                    checked={checked}
                    handleDeleteChip={handleDeleteChip}
                />
            )}
            <Box my={2}>
                <FilterChip filterQ={filterQ} setFilterQ={setFilterQ} />
            </Box>
            <Brands
                checked={checked}
                brandsForFilter={brandsForFilter}
                handleCheckBrand={handleCheckBrand}
            />
            {!price.max && !price.min && (
                <>
                    <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                        Precio
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            placeholder="Mínimo"
                            type="number"
                            variant="outlined"
                            value={selectedPrice.min}
                            InputProps={{
                                className: classes.priceInput,
                            }}
                            onChange={(e) => handleChangePrice(e, 'min')}
                            onKeyPress={handleKeyPress}
                        />
                        <Divider width={15} style={{ margin: '0 5px' }} />
                        <TextField
                            placeholder="Máximo"
                            value={selectedPrice.max}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                className: classes.priceInput,
                            }}
                            onChange={(e) => handleChangePrice(e, 'max')}
                            onKeyPress={handleKeyPress}
                        />
                        <IconButton
                            style={{ marginLeft: '20px' }}
                            edge="end"
                            disabled={
                                selectedPrice.min === null
                                && selectedPrice.max === null
                            }
                            onClick={() => {
                                handlePriceEntered();
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </>
            )}
            <TypeSpecs
                checked={checked}
                selected={type.type}
                handleCheckTypeSpec={handleCheckTypeSpec}
            />
            {mobile && (
                <Box
                    className={classes.bottomContainer}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    pb={1}
                >
                    <Divider width="100%" />
                    <Button
                        onClick={() => setShowMobileFilters(false)}
                        variant="contained"
                        style={{
                            height: '50px',
                            margin: '25px 35px',
                        }}
                    >
                        Aplicar filtros
                    </Button>
                    <Divider width="100%" />
                </Box>
            )}
        </>
    );
};

export default ProductFilterByType;
