import React, { useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { userReducer, userInitialState } from './User/reducer';
import cartHelper from '../helpers/cartHelper';
import history from '../helpers/history';
import storageHelper from '../helpers/storageHelper';
import API from '../api/API';
import userConstants from './User/userConstants';
import handler from '../helpers/handler';
import { CartProductTypes } from '../helpers/types';
import { sendCartItemQuantityModificationGAEvent, sendRemoveFromCartGAEvent, sendAddToCartGAEvent } from '../helpers/gaHelper';

export const UserContext = React.createContext({
    state: userInitialState,
    dispatch: () => null,
});

export const UserProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(userReducer, userInitialState);

    return (
        <UserContext.Provider value={[state, dispatch]}>
            { children }
        </UserContext.Provider>
    );
};

const triggerAPICartUpdate = _.debounce(async ({
    cart,
    dispatch,
    enqueueSnackbar,
}) => {
    const actualCart = { ...cart };
    const cartToSend = {
        ...actualCart,
        items: cartHelper.parseProductsToSend(actualCart.items),
    };
    if (!cartToSend.id) {
        const response = await API.carts.post();
        cartToSend.id = response.data.id;
    }
    return API.carts.updateCart(cartToSend)
        .then((response) => {
            cartHelper.warnIfPriceHasChanged(response.data.items, cartToSend);
            dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
        })
        .catch((error) => {
            dispatch({ type: userConstants.UPDATE_CART, cart: actualCart });
            const customErrorMessage = 'Ups! Tuvimos un problema y no pudimos sincronizar tu carrito. No se guardaron los últimos cambios. Probá nuevamente en unos minutos';
            handler.handleError({
                error, userContextDispatch: dispatch, enqueueSnackbar, customErrorMessage,
            });
        });
}, 2000);

export const useUser = () => {
    const context = React.useContext(UserContext);

    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    const [state, dispatch] = context;
    const currentItems = [...state.user?.cart?.items ?? []];
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => () => triggerAPICartUpdate.flush(), []);

    const update = (newCart) => {
        dispatch({ type: userConstants.UPDATE_CART, cart: newCart });
        triggerAPICartUpdate({ cart: newCart, dispatch, enqueueSnackbar });
    };

    const handleLogout = () => {
        storageHelper.clearStorage();
        API.auth.logout();
        dispatch({ type: userConstants.LOGOUT });
        history.push('/');
    };

    const removeProductFromCart = (product) => {
        const newItems = [...currentItems];
        const removedItems = _.remove(newItems, (i) => i.id === product.id);
        const newCart = {
            ...state.user.cart,
            items: newItems,
        };
        sendRemoveFromCartGAEvent(cartHelper.formatCartItemsForGAEvent(removedItems, true));
        update(newCart);
    };

    const getBuildQuantity = (build) => {
        let quantity = 0;
        state.user?.cart?.items.forEach((item) => {
            if (!item.buildId && item.id === build.id) quantity += 1;
        });
        return quantity;
    };
    const getItemInCartForProduct = (p) => state.user?.cart?.items?.find((item) => (
        !item.buildId && item.id === p.id
    ));

    const findItemInCartItems = (item, cartItems) => {
        const existingItemIndexOnCart = cartItems.findIndex(
            (i) => !i.buildId && i.id === item.id,
        );
        const isBuild = item.cartProductType === CartProductTypes.BUILD;
        return { existingItemIndexOnCart, isBuild };
    };

    const removeAssociatedBuildSOFromCartIfExists = (buildIndexToRemove, cartItems) => {
        const cartItemsCopy = [...cartItems];
        const { buildSubItemIndex } = cartItems[buildIndexToRemove];
        const SOIndexToRemove = cartItemsCopy.findIndex(
            (item) => item.buildId === buildSubItemIndex,
        );
        const so = SOIndexToRemove !== -1
            ? cartItemsCopy.splice(SOIndexToRemove, 1)[0]
            : null;
        return { so, cartItemsWithoutSO: cartItemsCopy };
    };

    const updateItemOnCart = (item) => {
        let cartItems = [...currentItems];
        const { existingItemIndexOnCart, isBuild } = findItemInCartItems(item, cartItems);
        console.log(existingItemIndexOnCart);
        const doesItemExistOnCart = existingItemIndexOnCart !== -1;

        if (isBuild && doesItemExistOnCart && item.quantity === null) {
            // Reducing build quantity, we need to remove an instance of the build
            const { so, cartItemsWithoutSO } = removeAssociatedBuildSOFromCartIfExists(
                existingItemIndexOnCart,
                state.user?.cart?.items,
            );
            cartItems = cartItemsWithoutSO;
            const buildRemoved = cartItems.splice(existingItemIndexOnCart, 1)[0];
            const itemsRemoved = [buildRemoved];
            if (so) itemsRemoved.push(so);
            sendRemoveFromCartGAEvent(cartHelper.formatCartItemsForGAEvent(itemsRemoved, true));
        } else if (isBuild) {
            // Increasing build quantity, we need to add an instance of the build
            cartItems.push({
                ...item,
                type: item.cartProductType,
                quantity: 1,
                buildSubItemIndex: uuidV4(),
            });
            sendAddToCartGAEvent([{
                item_id: item.id,
                item_name: item.name,
                item_category: item.type,
                price: item.price,
                quantity: 1,
            }]);
        } else if (doesItemExistOnCart && !isBuild) {
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                },
                oldQuantity: cartItems[existingItemIndexOnCart].quantity,
                newQuantity: item.quantity,
            });
            cartItems[existingItemIndexOnCart].quantity = item.quantity;
        } else {
            cartItems.push({ ...item, type: item.cartProductType });
            sendAddToCartGAEvent([{
                item_id: item.id,
                item_name: item.name,
                item_category: item.type,
                price: item.price,
                quantity: item.quantity,
            }]);
        }

        update({ ...state.user.cart, items: cartItems });
    };

    // FIXME: This is almost the same as updateItemOnCart, refactor
    const addItemOnCart = (item) => {
        const cartItems = [...currentItems];
        const { existingItemIndexOnCart, isBuild } = findItemInCartItems(item, cartItems);

        const oldQuantity = existingItemIndexOnCart !== -1
            ? cartItems[existingItemIndexOnCart].quantity
            : 0;

        if (isBuild) {
            // Adding a build, we need to add item.quantity instances of the build
            for (let i = 0; i < item.quantity; i += 1) {
                cartItems.push({
                    ...item,
                    type: item.cartProductType,
                    quantity: 1,
                    buildSubItemIndex: uuidV4(),
                });
            }
            sendAddToCartGAEvent([{
                item_id: item.id,
                item_name: item.name,
                item_category: item.type,
                price: item.price,
                quantity: item.quantity,
            }]);
        } else if (existingItemIndexOnCart !== -1 && !isBuild) {
            cartItems[existingItemIndexOnCart].quantity = item.quantity;
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                },
                oldQuantity,
                newQuantity: item.quantity,
            });
        } else {
            cartItems.push({ ...item, type: item.cartProductType });
            sendAddToCartGAEvent([{
                item_id: item.id,
                item_name: item.name,
                item_category: item.type,
                price: item.price,
                quantity: item.quantity,
            }]);
        }
        update({ ...state.user.cart, items: cartItems });
    };

    const hasProduct = (p) => Boolean(getItemInCartForProduct(p));
    const clear = () => {
        sendRemoveFromCartGAEvent(state.user.cart?.items?.map((item) => {
            const result = cartHelper.formatCartItemForGAEvent(item, true);
            if (item.buildId) result.for_probuild_id = item.buildId;
            return result;
        }));
        const clearedCart = { ...state.user.cart };
        clearedCart.items = [];
        return API.carts.updateCart(clearedCart)
            .then((response) => {
                dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                enqueueSnackbar('Se ha limpiado el carrito.', { variant: 'success' });
            })
            .catch((error) => {
                const customErrorMessage = 'Hubo un problema al limpiar el carrito. Por favor intente nuevamente en unos minutos';
                handler.handleError({
                    error, userContextDispatch: dispatch, enqueueSnackbar, customErrorMessage,
                });
                throw error;
            });
    };
    return {
        state,
        dispatch,
        handleLogout,
        cart: {
            addItemOnCart,
            updateItemOnCart,
            getBuildQuantity,
            removeProductFromCart,
            update,
            getItemInCartForProduct,
            hasProduct,
            clear,
        },
    };
};
