import React from 'react';
import {
    Box,
    Typography,
    Container,
    IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ProductFilters from '../ProductFilters';
import { useMobileFiltersStyles } from './styles';

const MobileFilters = ({
    price,
    route,
    checked,
    filterQ,
    setPrice,
    setFilterQ,
    setChecked,
    selectedType,
    handleSelect,
    selectedPrice,
    setSelectedType,
    handleDeleteChip,
    setSelectedPrice,
    handleChangeCheck,
    handleChangePrice,
    handlePriceEntered,
    setShowMobileFilters,
}) => {
    const classes = useMobileFiltersStyles();
    return (
        <Box className={classes.containerMobile}>
            <Container>
                <Box pt={1}>
                    <IconButton onClick={() => setShowMobileFilters(false)}>
                        <ArrowBackIcon style={{ fill: '#1C1C1C' }} />
                    </IconButton>
                </Box>
                <Box>
                    <Typography variant="h6" style={{ color: '#727272' }}>
                        FILTRAR POR
                    </Typography>
                </Box>
                <ProductFilters
                    checked={checked}
                    filterQ={filterQ}
                    handleChangeCheck={handleChangeCheck}
                    handleChangePrice={handleChangePrice}
                    handleDeleteChip={handleDeleteChip}
                    handlePriceEntered={handlePriceEntered}
                    handleSelect={handleSelect}
                    mobile
                    price={price}
                    route={route}
                    selectedPrice={selectedPrice}
                    selectedType={selectedType}
                    setChecked={setChecked}
                    setFilterQ={setFilterQ}
                    setPrice={setPrice}
                    setSelectedPrice={setSelectedPrice}
                    setSelectedType={setSelectedType}
                    setShowMobileFilters={setShowMobileFilters}
                />
            </Container>
        </Box>
    );
};

export default MobileFilters;
