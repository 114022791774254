import React, { useEffect } from 'react';
import { ProductTypes } from '../../../../helpers/types';
import BaseProductSelect from '../BaseProductSelect';

const GPUSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed, setSelectedGPU,
}) => {
    const validateNextStepAllowed = () => {
        setGoToNextAllowed(true);
    };

    useEffect(() => {
        const hasGPU = (buildSpecs.gpu && buildSpecs.gpu?.quantity >= 1)
        || (buildSpecs.cpu?.specs.igpu && buildSpecs.mother?.specs.video_output);
        setSelectedGPU(hasGPU);
    }, [buildSpecs.cpu?.specs.igpu, buildSpecs.gpu, buildSpecs.mother?.specs.video_output,
        setSelectedGPU]);

    return (
        <BaseProductSelect
            updateBuildSpecs={updateBuildSpecs}
            buildSpecs={buildSpecs}
            productType={ProductTypes.GPU}
            buildProduct={buildSpecs.gpu}
            validateNextStepAllowed={validateNextStepAllowed}
        />
    );
};

export default GPUSelect;
