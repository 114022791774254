import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Button, makeStyles, Paper, CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import emptyUser from './emptyUser.svg';
import API from '../../api/API';
import imageHelper from '../../helpers/imageHelper';
import handler from '../../helpers/handler';
import { UserContext } from '../../contexts';
import userConstants from '../../contexts/User/userConstants';

const useStyles = makeStyles((theme) => ({
    userProfile: {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundSize: 'cover',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: 85,
            height: 85,
        },
        [theme.breakpoints.up('sm')]: {
            width: 128,
            height: 128,
        },
    },
    textProfile: {
        backgroundColor: 'rgba(28, 28, 28, 0.5)',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            width: 85,
        },
        [theme.breakpoints.up('sm')]: {
            width: 128,
        },
    },
}));

const UserPhotoEdit = ({ userPicture }) => {
    const classes = useStyles();
    const history = useHistory();
    const inputFile = useRef(null);
    const [user, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [fileUrl, setFileUrl] = useState(emptyUser);
    const [editing, setEditing] = useState(false);

    const handleFileInputChange = (e) => new Promise(() => {
        setEditing(true);
        imageHelper.convertPhotoToBase64(e.target.files[0])
            .then((result) => API.users.patchMe({ image: result }))
            .then((response) => {
                const newFileURL = response.data.user.profileImageURL;
                enqueueSnackbar('Su foto de perfil ha sido actulizada correctamente', { variant: 'success' });
                setFileUrl(newFileURL);
                dispatch({
                    type: userConstants.UPDATE_PROFILE_PICTURE,
                    profileImageURL: newFileURL,
                });
            })
            .catch((err) => {
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                    history,
                    redirectToHome: true,
                });
            })
            .finally(() => setEditing(false));
    });
    useEffect(() => {
        if (userPicture) setFileUrl(userPicture);
        else setFileUrl(emptyUser);
    }, [userPicture]);

    return (
        <Paper square className={classes.userProfile} style={{ backgroundImage: `url(${fileUrl})` }}>
            <Button
                className={classes.textProfile}
                onClick={() => inputFile.current.click()}
            >
                {editing ? (
                    <>
                        Editando
                        {' '}
                        <CircularProgress color="primary" size={16} />
                    </ >
                ) : 'Editar'}
            </Button>
            <input
                type="file"
                name="imagen"
                accept="image/*"
                ref={inputFile}
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
            />
        </Paper>
    );
};

export default UserPhotoEdit;
