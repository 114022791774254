import React, { useEffect, useState, useContext } from 'react';
import {
    Box, CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { UserContext } from '../../contexts';
import API from '../../api/API';
import handler from '../../helpers/handler';
import PageContent from '../components/PageContent';
import UserInfoCard from './UserInfoCard';
import UserData from './UserData';
import CompletedOrders from './CompletedOrders';
import CurrentOrders from './CurrentOrders';
import CollapsibleOrders from './CollapsibleOrders';
import { helmetUserProfileConfig } from '../../data/seo';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const COLLAPSIBLE_ORDER_TYPES = {
    rma: 'rma',
    cancelled: 'cancelled',
};

const UserProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState(null);
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [areOrdersLoaded, setAreOrdersLoaded] = useState(false);
    const [user, setUser] = useState([]);
    const [orders, setOrders] = useState([]);
    const [state, dispatch] = useContext(UserContext);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);
    useEffect(() => {
        API.users.me()
            .then((response) => {
                setUser(response.data);
            })
            .catch((err) => {
                setError(err.message);
                handler.handleError({ error: err, userContextDispatch: dispatch, enqueueSnackbar });
            })
            .finally(() => setIsUserLoaded(true));
        API.orders.get()
            .then((response) => {
                setOrders(response.data);
            })
            .catch((err) => {
                if (err.response?.status !== 401) {
                    enqueueSnackbar('Hubo un problema recuperar tus compras. Por favor intentá nuevamente en unos minutos', { variant: 'error' });
                }
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    enqueueSnackbar,
                });
            })
            .finally(() => setAreOrdersLoaded(true));
    }, [enqueueSnackbar, history, dispatch]);

    if (error) {
        return (
            <PageContent>
                Error:
                {error}
            </PageContent>
        );
    } if (!isUserLoaded) {
        return (
            <PageContent>
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </PageContent>
        );
    }
    return (
        <>
            <Helmet>
                <title>{helmetUserProfileConfig.title}</title>
                <meta property="og:title" content={helmetUserProfileConfig.title} />
                <meta name="description" content={helmetUserProfileConfig.description} />
                <meta name="og:description" content={helmetUserProfileConfig.description} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <PageContent>
                <Box display="flex" className={classes.root}>
                    <UserInfoCard
                        userPicture={user.profileImageURL ?? null}
                        name={`${user.first_name} ${user.last_name}`}
                    />
                    <Box pl={{ xs: 0, sm: 2, md: 3 }} flexGrow={1}>
                        <UserData user={user} />
                        {areOrdersLoaded
                            ? (
                                <Box>
                                    <CurrentOrders orders={orders} />
                                    <CompletedOrders orders={orders} />
                                    <CollapsibleOrders
                                        orders={orders}
                                        ordersType={COLLAPSIBLE_ORDER_TYPES.rma}
                                    />
                                    <CollapsibleOrders
                                        orders={orders}
                                        ordersType={COLLAPSIBLE_ORDER_TYPES.cancelled}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <CircularProgress />
                                </Box>
                            )}
                    </Box>
                </Box>
            </PageContent>
        </>
    );
};

export default UserProfile;
