import React, { useEffect } from 'react';
import {
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import intel from '../../images/intel.svg';
import intelSelected from '../../images/intelselected.svg';
import amd from '../../images/amd.svg';
import amdSelected from '../../images/amdselected.svg';
import ImageRadio from '../../../ImageRadio';

const useStyles = makeStyles(() => ({
    box: {
        maxWidth: 605,
        marginTop: 98,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

const CPUBrandSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed,
}) => {
    const classes = useStyles();

    const handleClick = (cpuBrand) => {
        updateBuildSpecs({
            cpuBrand: buildSpecs.cpuBrand && buildSpecs.cpuBrand === cpuBrand ? null : cpuBrand,
        });
    };

    useEffect(() => {
        setGoToNextAllowed(!!buildSpecs.cpuBrand);
    }, [buildSpecs.cpuBrand, setGoToNextAllowed]);

    return (
        <>
            <Box className={classes.box} mx="auto" display="flex">
                <ImageRadio
                    key="intel"
                    image={buildSpecs.cpuBrand === 'intel' ? intelSelected : intel}
                    title="Intel"
                    onClick={() => handleClick('intel')}
                    checked={buildSpecs.cpuBrand === 'intel'}
                    defaultImage={intel}
                />
                <ImageRadio
                    key="amd"
                    image={buildSpecs.cpuBrand === 'amd' ? amdSelected : amd}
                    title="AMD"
                    onClick={() => handleClick('amd')}
                    checked={buildSpecs.cpuBrand === 'amd'}
                    defaultImage={amd}
                />
            </Box>
        </>
    );
};

export default CPUBrandSelect;
