import monitor from './images/monitor.png';
import { ProductTypes } from '../helpers/types';
import mouse from './images/mouse.png';
import keyboard from './images/keyboard.png';
import headphones from './images/headphone.png';
import chairs from './images/chair.png';
import accessories from './images/accessories.png';
import speakers from './images/speaker.png';
import desks from './images/desk.png';
import monitorIcon from './icons/monitor.svg';
import mouseIcon from './icons/mouse.svg';
import keyboardIcon from './icons/keyboard.svg';
import headphonesIcon from './icons/headphone.svg';
import microphoneIcon from './icons/microphone.svg';
import chairsIcon from './icons/chair.svg';
import accessoriesIcon from './icons/accessories.svg';
import speakersIcon from './icons/speaker.svg';
import desksIcon from './icons/desk.svg';

const peripheralTypes = [
    {
        title: 'Accesorios',
        image: accessories,
        icon: accessoriesIcon,
        type: ProductTypes.ACCESSORIES,
    },
    {
        title: 'Auriculares',
        image: headphones,
        icon: headphonesIcon,
        type: ProductTypes.HEADSET,
    },
    {
        title: 'Micrófonos',
        image: microphoneIcon,
        icon: microphoneIcon,
        type: ProductTypes.MICROPHONE,
    },
    {
        title: 'Mobiliario',
        image: desks,
        icon: desksIcon,
        type: ProductTypes.FURNITURE,
    },
    {
        title: 'Monitores',
        image: monitor,
        icon: monitorIcon,
        type: ProductTypes.MONITOR,
    },
    {
        title: 'Mouse',
        image: mouse,
        icon: mouseIcon,
        type: ProductTypes.MOUSE,
    },
    {
        title: 'Parlantes',
        image: speakers,
        icon: speakersIcon,
        type: ProductTypes.SPEAKERS,
    },
    {
        title: 'Sillas',
        image: chairs,
        icon: chairsIcon,
        type: ProductTypes.CHAIR,
    },
    {
        title: 'Teclados',
        image: keyboard,
        icon: keyboardIcon,
        type: ProductTypes.KEYBOARD,
    },
];

export default peripheralTypes;
