/* eslint-disable mui-unused-classes/unused-classes */
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../theme/colors';
import GDTheme from '../../../theme/GDTheme';

const useStyles = makeStyles(() => ({
    actionButtons: {
        alignItems: 'center',
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        margin: '48px 0px',
    },
    actualStep: {
        color: '#1C1C1C !important',
        fontSize: 12,
        fontWeight: 700,
        marginBottom: 64,
        marginTop: 10,
        textTransform: 'none',
    },
    completeOrderContainer: {
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 959px)': {
            flexDirection: 'column',
        },
    },
    completeOrderContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    containerProducts: {
        height: '150px',
        overflowX: 'scroll',
        width: '100%',
    },
    modal: {
        backgroundColor: '#FFF',
        height: 'auto',
        left: '50%',
        padding: '20px 20px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '45%',
        '@media (max-width: 900px)': {
            width: '80vw',
            height: 'auto',
        },
    },
    productContainer: {
        alignItems: 'center',
        backgroundColor: '#f1f1f1',
        border: '10px solid #F8E83 !important',
        padding: '10px',
    },
    steps: {
        background: colors.grey,
        color: colors.black,
        '@media (min-width: 960px)': {
            maxHeight: 410,
        },
    },
    stepItem: {
        marginBottom: 45,
        marginLeft: 10,
        marginTop: 2,
        textAlign: 'left',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    step: {
        color: '#727272',
        marginBottom: 65,
        marginTop: 10,
        textTransform: 'none',
    },
    stepChangeButton: {
        height: 34,
        width: 129,
    },
    stepContent: {
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        '@media (max-width: 959px)': {
            marginTop: GDTheme()
                .spacing(2),
        },
        '@media (max-width: 769px)': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    stepsMobile: {
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'hidden',
        '@media (max-width: 665px)': {
            justifyContent: 'flex-start',
        },
    },
}));

export default useStyles;
