import componentsTypes from '../../data/componentsTypes';
import peripheralTypes from '../../data/peripheralTypes';
import { pcGamer } from '../../data/buildTypes';
import { helmetProductsConfig, helmetProductsPageConfig } from '../../data/seo';
import productHelper from '../../helpers/productHelper';
import { notebooksType, offersRoute } from './constants';

export const isOffersRoute = (route) => route === offersRoute;

const isFirstParamAType = (pageOrType) => Number.isNaN(parseInt(pageOrType, 10));

const getHelmetConfig = ({ pageOrType }) => {
    if (!pageOrType) return helmetProductsPageConfig;
    const isType = isFirstParamAType(pageOrType);
    if (!isType) return helmetProductsPageConfig;

    const config = helmetProductsConfig.find(({ url }) => url === pageOrType);
    if (!config) return helmetProductsPageConfig;
    return config;
};

export const getHelmetTitle = (params) => getHelmetConfig(params).title;

export const getHelmetDescription = (params) => getHelmetConfig(params).description;

export const getSelectedType = ({ pageOrType }) => {
    if (!pageOrType) return null;
    const isType = isFirstParamAType(pageOrType);
    if (!isType) return null;

    const config = helmetProductsConfig.find(({ url }) => url === pageOrType);
    if (config.url === 'pcs-escritorio-gaming') return pcGamer;
    if (config.url === 'notebooks-gaming') return notebooksType;

    const foundComponentType = componentsTypes.find(({ type }) => type === config.type);
    const foundComponentSubType = componentsTypes.filter(({ subTypes }) => subTypes)
        .map(({ subTypes }) => subTypes)
        .flat()
        .find(({ type }) => type === config.type);

    // peripheralTypes has not items with subTypes
    const peripheralType = peripheralTypes.find(({ type }) => type === config.type);

    return foundComponentType || foundComponentSubType || peripheralType || null;
};

export const getRoutePath = ({ params, selectedType, pageRoute }) => {
    const pageOrType = params?.pageOrType;
    const page = params?.page;

    let route = pageRoute;

    if (selectedType && !pageOrType) {
        const config = helmetProductsConfig.find(({ type }) => type === selectedType.type);
        route += `/${config.url}`;
        return route;
    }
    if (pageOrType) {
        route += `/${pageOrType}`;
    }
    if (page) {
        route += `/${page}`;
    }
    return route;
};

export const getRoutePathWithoutPage = (params, pageRoute) => {
    let route = pageRoute;
    const { pageOrType } = params;
    if (pageOrType) {
        const isType = isFirstParamAType(pageOrType);
        if (isType) {
            route += `/${pageOrType}`;
        }
    }
    return route;
};

export const getInitialSort = (query) => (
    query.has('sort') && Object.values(productHelper.SORT_VALUES).includes(query.get('sort'))
        ? query.get('sort')
        : productHelper.SORT_VALUES.popularity
);

export const getChipsForPrice = (priceToSet) => {
    if (priceToSet.min !== null && priceToSet.max !== null) {
        return {
            [`$${priceToSet.min} a $${priceToSet.max}`]: true,
        };
    } if (priceToSet.min !== null) {
        return {
            [`Desde $${priceToSet.min}`]: true,
        };
    }
    return {
        [`Hasta $${priceToSet.max}`]: true,
    };
};

const deleteEmptySpecsFromFilter = (specsToFilter) => {
    const newSpecs = specsToFilter;
    Object.entries(newSpecs).forEach((selectedSpec) => {
        if (selectedSpec[1].length === 0
            || !selectedSpec[1]
            || selectedSpec[1][1] === undefined
        ) {
            delete newSpecs[selectedSpec[0]];
        }
    });
    return newSpecs;
};

export const prepareFilters = ({
    filterQ,
    price,
    selectedPrice,
    selectedBrands,
    setSelectedBrands,
    selectedSpecs,
}) => {
    let filter = {};
    if (filterQ) {
        filter = {
            ...filter,
            fuzzySearch: filterQ,
        };
    }
    if ((price.max && !price.min) || (!selectedPrice.min && selectedPrice.max)) {
        filter = {
            ...filter,
            price: {
                special: {
                    ARS: {
                        lte: Number(price.max) || Number(selectedPrice.max),
                    },
                },
            },
        };
    }
    if ((price.min && !price.max) || (selectedPrice.min && !selectedPrice.max)) {
        filter = {
            ...filter,
            price: {
                special: {
                    ARS: {
                        gte: Number(price.min) || Number(selectedPrice.min),
                    },
                },
            },
        };
    }
    if ((price.min && price.max) || (selectedPrice.min && selectedPrice.max)) {
        filter = {
            ...filter,
            price: {
                special: {
                    ARS: {
                        gte: Number(price.min) || Number(selectedPrice.min),
                        lte: Number(price.max) || Number(selectedPrice.max),
                    },
                },
            },
        };
    }
    if (selectedBrands) {
        if (selectedBrands.brand?.length === 0) {
            setSelectedBrands(null);
        }
        filter = {
            ...filter,
            ...selectedBrands,
        };
    }

    if (selectedSpecs) {
        const specsToFilter = deleteEmptySpecsFromFilter(selectedSpecs);
        filter = {
            ...filter,
            specs: {
                ...specsToFilter,
            },
        };
    }

    return filter;
};

export const handleArraysForBrands = ({ newBrand, selectedBrands }) => {
    const brands = selectedBrands;
    if (brands
        && brands.brand
        && brands.brand.includes(newBrand)) {
        if (typeof brands.brand === 'string') {
            brands.brand = [brands.brand];
        }
        return brands.brand
            .filter((specInArray) => specInArray !== newBrand).flat();
    }
    if (!brands) {
        return newBrand;
    }
    const newSelectedBrands = [];
    newSelectedBrands.push(brands.brand);
    newSelectedBrands.push(newBrand);
    return newSelectedBrands.flat();
};

export const handleArraysForSpecs = ({ newSpec, specType, selectedSpecs }) => {
    const specs = selectedSpecs;
    if (specs
        && specs[specType]
        && specs[specType].includes(newSpec)) {
        if (typeof specs[specType] === 'string') {
            specs[specType] = [specs[specType]];
        }
        return specs[specType]
            .filter((specInArray) => specInArray !== newSpec).flat();
    }
    if (!specs) {
        return newSpec;
    }
    const newSelectedSpecs = [];
    newSelectedSpecs.push(specs[specType]);
    newSelectedSpecs.push(newSpec);
    return newSelectedSpecs.flat();
};
