import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import GDHeader from './GDHeader';
import GDRouter from './GDRouter';
import GDTheme from './theme/GDTheme';
import GDFooter from './GDFooter';
import { UserProvider } from './contexts';
import ProductsContextProvider from './contexts/Products/context';
import { SnackbarUtilsConfigurator } from './SnackBarUtils';

const theme = GDTheme();

function App() {
    return (
        <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <UserProvider>
                <ProductsContextProvider>
                    <Router>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <GDHeader />
                            <GDRouter />
                            <GDFooter />
                        </ThemeProvider>
                    </Router>
                </ProductsContextProvider>
            </UserProvider>
        </SnackbarProvider>
    );
}

export default App;
