/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Box, Typography, Tooltip, Button,
} from '@material-ui/core';
import helper from '../../helpers/cartHelper';
import intel from '../pro-builder/images/intelselected.svg';
import amd from '../pro-builder/images/amdselected.svg';
import logo from '../../logoMobile.svg';

const useStyles = makeStyles((theme) => ({
    stepButton: {
        color: '#727272',
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 300,
        paddingTop: '40px',
        '&:disabled': {
            color: '#727272',
        },
    },
    stepButtonSelected: {
        color: '#F8F9FB',
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 300,
        paddingTop: '40px',
    },
    tooltipName: {
        fontWeight: '700!important',
        color: '#727272',
    },
    dash: {
        margin: '0 5px!important',
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        '&:before': {
            border: '1px solid #E6E8ED',
        },
        color: '#FFFFFF',
    },
}))(Tooltip);

const StepButton = ({
    buildSpecs, actualStep, step, steps, index, handleClick, lastStep,
}) => {
    const classes = useStyles();
    const [openTooltip, setOpenTooltip] = useState(false);
    const tooltipTitle = (spec) => {
        let tooltipImg = logo;
        if (spec === 'intel') {
            tooltipImg = intel;
        }
        if (spec === 'amd') {
            tooltipImg = amd;
        }
        if (spec && spec.images && spec.images[0]) {
            tooltipImg = spec.images[0].url;
        }
        return (
            <Box display="flex" alignItems="center">
                <img
                    src={tooltipImg}
                    alt=""
                    width={40}
                    height={40}
                />
                <Box ml={2} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="space-between">
                    <Typography color="inherit" variant="subtitle2" className={classes.tooltipName}>
                        {spec
                            ? (spec.name || spec || 'Nada por ahora')
                            : 'Nada por ahora'}
                    </Typography>
                    <Box display="flex" justifyContent="flex-start">
                        <Typography variant="subtitle2">
                            {`(x ${spec
                            && spec.specs
                                ? spec.quantity || 1
                                : 1}) 
                        `}
                        </Typography>
                        <Typography m={2} className={classes.dash} variant="subtitle2">
                            {' - '}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`${spec
                                && spec.price
                                ? helper.formatPrice((parseInt(spec.price.special.ARS || 0, 10))
                                    * (parseInt(spec.quantity || 1, 10)))
                                : helper.formatPrice(0)}
                        `}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    };

    const hasBuildSpecs = (stepSpecs) => (stepSpecs && ((
        Array.isArray(stepSpecs) && stepSpecs.length > 0)
            || (!Array.isArray(stepSpecs) && stepSpecs !== '')));

    const getTooltipTitle = () => {
        const stepSpecs = buildSpecs[step.name];
        if (!hasBuildSpecs(stepSpecs)) return null;
        if (Array.isArray(stepSpecs)) {
            return stepSpecs.map((specFromArray) => (tooltipTitle(specFromArray)));
        }
        return tooltipTitle(stepSpecs);
    };

    const isStepDisabled = () => {
        const previousStep = index !== 0 ? steps[index - 1] : index;
        if (step.name === 'storage'
            && buildSpecs.mother?.specs.video_output
            && buildSpecs.cpu?.specs.igpu
            && (hasBuildSpecs(buildSpecs.ram))) {
            return false;
        }
        return (index === actualStep && !lastStep)
            || (!hasBuildSpecs(buildSpecs[previousStep.name])
             && !hasBuildSpecs(buildSpecs[step.name]));
    };

    const button = (
        <Button
            variant="text"
            className={actualStep === index && !lastStep
                ? classes.stepButtonSelected
                : classes.stepButton}
            disabled={isStepDisabled()}
            onClick={() => {
                setOpenTooltip(false);
                handleClick(index);
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
        >
            {step.title}
        </Button>
    );

    return !hasBuildSpecs(buildSpecs[step.name]) ? button : (
        <HtmlTooltip
            classes={{ arrow: classes.arrow }}
            title={getTooltipTitle()}
            placement="right"
            arrow
            open={openTooltip}
            onOpen={() => setOpenTooltip(true)}
            onClose={() => setOpenTooltip(false)}
        >
            {button}
        </HtmlTooltip>
    );
};

export default StepButton;
