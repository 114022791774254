import { Box } from '@material-ui/core';
import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import CartLinkedProductCard from '../products-page/CartLinkedProductCard';
import './carousel-style.css';

const ProductsCarousel = ({ builds }) => {
    const breakPoints = [
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 460, itemsToShow: 2, pagination: false },
        { width: 710, itemsToShow: 3, pagination: false },
        { width: 960, itemsToShow: 4, pagination: false },
        { width: 1210, itemsToShow: 5, pagination: false },
        { width: 1460, itemsToShow: 6, pagination: false },
    ];
    const carouselRef = React.useRef(null);

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? 'prev' : 'next';
        const style = type === consts.PREV
            ? 'GDCarousel__arrows GDCarousel__arrowLeft'
            : 'GDCarousel__arrows GDCarousel__arrowRight';
        return (
            <button
                onClick={onClick}
                type="button"
                disabled={isEdge}
                className={style}
            >
                <span>{pointer}</span>
            </button>
        );
    };

    return (
        <Carousel
            itemsToShow={4}
            itemPadding={[35, 0]}
            breakPoints={breakPoints}
            className="carousel-university"
            ref={carouselRef}
            disableArrowsOnEnd={false}
            renderArrow={myArrow}
            isRTL={false}
        >
            {builds.map((build) => (
                <Box width={224} key={build.id}>
                    <CartLinkedProductCard product={build} />
                </Box>
            ))}
        </Carousel>

    );
};

export default ProductsCarousel;
