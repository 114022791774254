import React from 'react';
import { ProductTypes } from '../../../../helpers/types';
import BaseProductSelect from '../BaseProductSelect';

const CaseSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed,
}) => {
    const validateNextStepAllowed = () => {
        setGoToNextAllowed(buildSpecs.case && buildSpecs.case.quantity >= 1);
    };

    return (
        <BaseProductSelect
            updateBuildSpecs={updateBuildSpecs}
            buildSpecs={buildSpecs}
            validateNextStepAllowed={validateNextStepAllowed}
            productType={ProductTypes.CASE}
            buildProduct={buildSpecs.case}
        />
    );
};

export default CaseSelect;
