export const organizer = [
    'Gamer Factory, con sitio web en gamerfactory.com.ar y cuenta de Instagram @gamerfactory.ar.',
];

export const qeographicScope = [
    'Este sorteo es válido únicamente para residentes de Argentina',
];

export const duration = [
    'El sorteo comenzará el 23/8/2024 y finalizará el 06/09/2024.',
];

export const award = [
    'Una Memoria RAM Corsair 8GB DDR4 3200 MHZ Vengeance Pro RGB Black para cada ganador, el sorteo contará con dos ganadores.',
];

export const howToTakePart = [
    '* Seguir la cuenta de Instagram @gamerfactory.ar.',
    '* Dar "Me gusta" a la publicación del sorteo.',
    '* Etiquetar a tres (3) amigos en los comentarios de la publicación del sorteo.',
];

export const winnerSelection = [
    'Los ganadores serán seleccionados al azar entre todos los participantes que cumplan con los requisitos de participación. La selección se realizará el día 28/6/2024, y el resultado será anunciado en nuestra cuenta de Instagram y contactado directamente por mensaje privado.',
];

export const awardCeremony = [
    'El premio será enviado a los ganadores por correo sin ningún costo adicional o puede ser retirado en persona, según se coordine con el ganador',
];

export const generalConditions = [
    '* Este sorteo no está afiliado, patrocinado ni administrado por Instagram ni ninguna otra entidad aparte de Gamer Factory.',
    '* Cada participante asegura que es mayor de 18 años y cumple con los requisitos de residencia para participar en el sorteo.',
    '* El premio no es transferible ni canjeable por dinero.',
    '* Al participar, los usuarios aceptan que su nombre y fotografía puedan ser utilizados para fines promocionales relacionados con el sorteo, previo consentimiento explícito durante la participación.',
    '* Si un ganador no responde dentro de 48 horas después de ser contactado, se seleccionará un nuevo ganador.',
];

export const dataProtection = [
    'Los participantes aceptan que sus datos personales proporcionados serán utilizados únicamente para la gestión del sorteo. Estos datos serán tratados confidencialmente, protegidos mediante medidas de seguridad adecuadas y retenidos solo durante el tiempo necesario para cumplir con el propósito del sorteo.',
];

export const modifications = [
    'Gamer Factory se reserva el derecho de modificar o anular las bases del sorteo si se considera necesario por causas justificadas.',
];

export const acceptanceOfTheBases = [
    'La participación en este sorteo implica la total aceptación de estas bases y condiciones.',
];
