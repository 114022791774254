import React, {
    useState, useEffect, useMemo, useContext, Fragment,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Container,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    TextField,
    Collapse,
    Modal,
    CircularProgress,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import handler from '../../../../helpers/handler';
import colors from '../../../../theme/colors';
import { UserContext } from '../../../../contexts';
import Pin from '../google_maps_pin.png';
import API from '../../../../api/API';
import userConstants from '../../../../contexts/User/userConstants';
import storageHelper from '../../../../helpers/storageHelper';
import shippingDataHelper from '../../../../helpers/shippingDataHelper';
import ShippingAgencyMap from './ShippingAgencyMap';
import cartHelper from '../../../../helpers/cartHelper';
import { SHIPPING_TYPES } from '../../../../common/constants';
import { isSubsidizedShippingPrice } from '../../../../data/constants';

const useStyles = makeStyles((theme) => ({
    agencyNotFoundModal: {
        width: 683,
        height: 512,
        background: '#ffffff',
    },
    formContainer: {
        paddingBottom: 30,
    },
    headerBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 30,
        marginLeft: 24,
        marginBottom: 33,
    },
    closeButton: {
        height: 14,
        width: 14,
        fontSize: 14,
        fontWeight: 200,
        textDecoration: 'none',
        color: colors.blackGrey,
    },
    stepChangeBn: {
        width: 112,
        height: 30,
        marginTop: 0,
    },
    modalMap: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '0px',
            display: 'none',
            visiblity: 'hidden',
        },
    },
    agenciesListContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        border: '1px solid #F8E837',
    },
    localitiesList: {
        maxHeight: 280,
        width: 300,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    agencyData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    agencyModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingIcon: {
        color: colors.black,
        marginRight: 5,
    },
}));

const ShippingAgencySelectionModalBody = ({
    availableProvinces,
    productId,
    selectedProvince,
    setDoorToDoorMode,
    setShippingData,
    shippingData,
    toggleAgenciesModalOpen,
    toggleProductShippingQuoteModal,
    initialShippingDataForAgencyChange,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const cart = storageHelper.getUserCart();
    const [state, dispatch] = useContext(UserContext);

    const [selectedLocality, setSelectedLocality] = useState({});
    const [uniqueLocalities, setUniqueLocalities] = useState([]);
    const [selectedProvinceMap, setSelectedProvinceMap] = useState(selectedProvince);

    const [matchedAgencies, setMatchedAgencies] = useState([]);
    const [availableAgencies, setAvailableAgencies] = useState([]);

    const [center, setCenter] = useState({ lat: -37.98, lng: -63.36 });
    const [zoom, setZoom] = useState(4);

    const handleChangeProvinceMap = (newProvince) => {
        setSelectedProvinceMap(newProvince);
        setShippingData(
            shippingDataHelper
                .getShippingDataForProvinceChange({
                    newProvince,
                    shippingData,
                }),
        );
        setMatchedAgencies([]);
    };

    const markers = useMemo(() => {
        if (matchedAgencies.length > 0) {
            return matchedAgencies.map((agency) => (
                <Box
                    key={agency.id}
                    lng={parseFloat(agency.long)}
                    lat={parseFloat(agency.lat)}
                    className="marker temporary-marker"
                >
                    <span>
                        <img src={Pin} alt="marcador" width="30px" height="52px" />
                    </span>
                </Box>
            ));
        }
        return null;
    }, [matchedAgencies]);

    useEffect(() => {
        async function setAgencies() {
            const agencies = await shippingDataHelper.getAvailableAgencies(selectedProvinceMap);
            setAvailableAgencies(agencies);
        }
        setAgencies();
    }, [selectedProvinceMap]);

    useEffect(() => {
        async function setLocalities() {
            const localities = await shippingDataHelper.getUniqueLocalities(availableAgencies);
            setUniqueLocalities(localities);
        }
        setLocalities();
    }, [availableAgencies]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setCenter({
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
            });
            setZoom(13);
        });
    }, []);

    const addAgency = ({ agency, price, agenciesValidForMap }) => {
        let agencyLatitude = agency.lat;
        let agencyLongitude = agency.long;
        if (agencyLatitude === null || agencyLongitude === null) {
            agencyLatitude = agenciesValidForMap[0].lat;
            agencyLongitude = agenciesValidForMap[0].long;
        }
        const newAgency = {
            ...agency,
            lat: agencyLatitude,
            long: agencyLongitude,
            price,
        };
        setMatchedAgencies((a) => [...a, newAgency]);
    };

    const onAgencyClick = (agency) => {
        const newShippingData = {
            ...shippingData,
            address: agency.address,
            locality: agency,
            price: agency.price,
            shippingType: SHIPPING_TYPES.pickupAtNearestAgency,
        };
        setShippingData(newShippingData);

        const { user } = state;
        const updatedUser = { ...user, shippingData: newShippingData };
        dispatch({ type: userConstants.SET_USER, user: updatedUser });
        storageHelper.setUser(updatedUser);

        toggleAgenciesModalOpen();
        setDoorToDoorMode(false);
        toggleProductShippingQuoteModal(false);
    };

    const mapRelocationBasedOnAgenciesData = (agenciesValidForMap) => {
        setCenter({
            lat: agenciesValidForMap.length === 0
                ? -37.98
                : parseFloat(agenciesValidForMap[0].lat),
            lng: agenciesValidForMap.length === 0
                ? -63.36
                : parseFloat(agenciesValidForMap[0].long),
        });
        setZoom(agenciesValidForMap.length === 0 ? 3 : 14);
    };

    const handleMatchedAgenciesCollapsable = (locality) => {
        if (selectedLocality[locality.id] === true) {
            setSelectedLocality(
                (prevState) => ({ ...prevState, [locality.id]: false }),
            );
            const newMatchedAgencies = matchedAgencies
                .filter((a) => a.name !== locality.name);
            setMatchedAgencies(newMatchedAgencies);
        } else {
            setSelectedLocality(
                (prevState) => ({ ...prevState, [locality.id]: true }),
            );
        }
    };

    const handleAgenciesDisplay = ({ locality }) => {
        setSelectedLocality((prevState) => {
            if (prevState[locality.id]) {
                return ({ ...prevState, [locality.id]: !prevState[locality.id] });
            }
            return ({ ...prevState, [locality.id]: 'loading' });
        });
        const newShippingData = { ...shippingData, locality };
        setShippingData(newShippingData);

        const filteredAgencies = availableAgencies
            .filter((agency) => agency.name.toUpperCase() === locality.name.toUpperCase());

        const agenciesValidForMap = filteredAgencies
            .filter((agency) => agency.lat !== null && agency.long !== null);
        mapRelocationBasedOnAgenciesData(agenciesValidForMap);

        if (selectedLocality) {
            const cartId = cart.id;
            const shippingDataForAgencyDisplay = {
                ...newShippingData,
                shippingType: SHIPPING_TYPES.pickupAtNearestAgency,
            };

            filteredAgencies
                .forEach((agency) => {
                    API.shipping
                        .quote({ productId, cartId, shippingData: shippingDataForAgencyDisplay })
                        .then((response) => {
                            const { price } = response.data;
                            addAgency({ agency, price, agenciesValidForMap });
                            handleMatchedAgenciesCollapsable(locality);
                        })
                        .catch((error) => {
                            setSelectedLocality(
                                (prevState) => ({ ...prevState, [locality.id]: false }),
                            );
                            enqueueSnackbar('Ups! Tuvimos un problema. Probá nuevamente en unos minutos', { variant: 'error' });
                            return handler.handleError({
                                error, userContextDispatch: dispatch, enqueueSnackbar,
                            });
                        });
                });
        }
    };

    const MatchedAgencies = ({ agencies }) => (
        <List component="div" disablePadding>
            {agencies.map((agency) => (
                <ListItem variant="h6" key={agency.id}>
                    <Grid
                        container
                        className={classes.agencyData}
                    >
                        <Grid item xs={12}>
                            <ListItemText>
                                {agency.address}
                            </ListItemText>
                            <Typography variant="subtitle1">{agency.socialReason}</Typography>
                        </Grid>
                        {isSubsidizedShippingPrice ? (
                            <Grid item xs={6}>
                                <Typography variant="h6" style={{ color: colors.green }}>
                                    ENVÍO GRATIS
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={6}>
                                <Typography variant="h6" style={{ fontWeight: 400 }}>
                                    Envío:
                                    {' '}
                                    <Typography variant="h6" style={{ display: 'inline' }}>
                                        {cartHelper.formatPrice(agency.price)}
                                    </Typography>
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.stepChangeBn}
                                form="modalShippingForm"
                                onClick={() => onAgencyClick(agency)}
                            >
                                Elegir
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>
            ))}
        </List>
    );

    const displayMatchedAgencies = (localityId, agencies, localityName) => {
        if (selectedLocality[localityId] === true) {
            const agenciesToDisplay = agencies.filter((agency) => agency.name === localityName);
            return (<MatchedAgencies agencies={agenciesToDisplay} />);
        }
        return null;
    };

    const IconToDisplay = ({ loading }) => {
        if (loading === 'loading') {
            return <CircularProgress className={classes.loadingIcon} size={15} />;
        }
        if (loading) return <ExpandLess />;
        return <ExpandMore />;
    };

    return (
        <div className={classes.agencyNotFoundModal}>
            <Box className={classes.headerBox}>
                <Typography variant="h5">
                    Elegir punto de retiro
                </Typography>
                <Button
                    onClick={() => {
                        toggleProductShippingQuoteModal(false);
                        toggleAgenciesModalOpen();
                        if (shippingData.locality === null
                            && initialShippingDataForAgencyChange !== {}) {
                            setShippingData(initialShippingDataForAgencyChange);
                        }
                    }}
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </Button>
            </Box>
            <ValidatorForm id="modalShippingForm" autoComplete="off">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6}>

                            <Box className={classes.formContainer}>
                                <Autocomplete
                                    value={selectedProvinceMap}
                                    autoComplete={false}
                                    disableClearable
                                    options={availableProvinces}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newProvince) => {
                                        handleChangeProvinceMap(newProvince);
                                    }}
                                    getOptionSelected={
                                        (option, value) => value.name === option.name
                                    }
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'no-autocomplete',
                                            }}
                                            label="Provincia"
                                            margin="none"
                                            validators={['required']}
                                            errorMessages="Seleccione la provincia"
                                        />
                                    )}
                                />
                            </Box>

                            <Box className={classes.formContainer}>
                                <Autocomplete
                                    id="disabled"
                                    disabled
                                    renderInput={(params) => (
                                        <TextField {...params} label="Localidad" variant="standard" />
                                    )}
                                />
                                <Box pt={3} pb={3} className={classes.localitiesList}>
                                    <List component="localities">
                                        {uniqueLocalities.map((locality) => (
                                            <Fragment key={locality.id}>
                                                <ListItem
                                                    button
                                                    selected
                                                    onClick={() => {
                                                        handleAgenciesDisplay({ locality });
                                                    }}
                                                >
                                                    <ListItemText primary={locality.name} />
                                                    <IconToDisplay
                                                        loading={selectedLocality[locality.id]}
                                                    />
                                                </ListItem>
                                                <Collapse
                                                    in={selectedLocality[locality.id]}
                                                    maxWidth="xl"
                                                    className={classes.agenciesListContainer}
                                                    unmountOnExit
                                                >
                                                    {displayMatchedAgencies(
                                                        locality.id,
                                                        matchedAgencies,
                                                        locality.name,
                                                    )}
                                                </Collapse>
                                            </Fragment>
                                        ))}
                                    </List>
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item xs={6} md={6}>

                            <Box className={classes.formContainer}>
                                <ShippingAgencyMap
                                    className={classes.modalMap}
                                    zoom={zoom}
                                    center={center}
                                    markers={markers}
                                />
                            </Box>

                        </Grid>

                    </Grid>
                </Container>
            </ValidatorForm>
        </div>
    );
};

const ShippingAgencySelectionModal = ({
    availableProvinces,
    openAgenciesModal,
    productId,
    selectedProvince,
    setDoorToDoorMode,
    setShippingData,
    shippingData,
    initialShippingDataForAgencyChange,
    toggleAgenciesModalOpen,
    toggleProductShippingQuoteModal = (() => { }),
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={openAgenciesModal}
            onClose={() => {
                toggleProductShippingQuoteModal(false);
                toggleAgenciesModalOpen();
                if (shippingData.locality === null
                    && initialShippingDataForAgencyChange !== {}) {
                    setShippingData(initialShippingDataForAgencyChange);
                }
            }}
            className={classes.agencyModal}
        >
            <ShippingAgencySelectionModalBody
                availableProvinces={availableProvinces}
                productId={productId}
                selectedProvince={selectedProvince}
                setDoorToDoorMode={setDoorToDoorMode}
                setShippingData={setShippingData}
                shippingData={shippingData}
                toggleAgenciesModalOpen={toggleAgenciesModalOpen}
                initialShippingDataForAgencyChange={initialShippingDataForAgencyChange}
                toggleProductShippingQuoteModal={toggleProductShippingQuoteModal}
            />
        </Modal>
    );
};

export default ShippingAgencySelectionModal;
