/* eslint-disable mui-unused-classes/unused-classes */
import { makeStyles } from '@material-ui/core';

export const useProductsPageStyles = makeStyles((theme) => ({
    containerMobile: {
        width: '100vw',
        backgroundColor: '#E5E5E5',
    },
    filterSpecButton: {
        justifyContent: 'flex-start',
        margin: 0,
        padding: '1px 0',
        color: '#727272',
        fontWeight: '300',
        textTransform: 'none',
        fontSize: '14px',
    },
    filtersContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
}));

export const useProductFiltersStyles = makeStyles((theme) => ({
    grid: {
        marginBottom: '16px',
    },
    filterContainer: {
        backgroundColor: '#E5E5E5',
        minHeight: '80vh',
    },
    filterCategoryTitle: {
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
    },
    filterCategoryTitleAndLink: {
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const useProductListStyles = makeStyles((theme) => ({
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));
