const ERROR_CODES = {
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    DIFFERENCE_IN_PRICES_ERROR: 'DIFFERENCE_IN_PRICES_ERROR',
    NOT_ENOUGH_STOCK_FOR_ORDER: 'NOT_ENOUGH_STOCK_FOR_ORDER',
    MP_PAYMENT_NOT_APPROVED: 'MP_PAYMENT_NOT_APPROVED',
    CART_NOT_FOUND_ERROR: 'CART_NOT_FOUND_ERROR',
    MAX_INSTALLMENTS_ERROR: 'MAX_INSTALLMENTS_ERROR',
    INVALID_COUPON_ERROR: 'INVALID_COUPON_ERROR',
    COUPON_EXPIRED_ERROR: 'COUPON_EXPIRED_ERROR',
    ORDER_VALIDATION_ERROR: 'ORDER_VALIDATION_ERROR',
    SELLING_DISABLED_ERROR: 'SELLING_DISABLED_ERROR',
};

const GO_TO_CART_ERRORS = [
    ERROR_CODES.DIFFERENCE_IN_PRICES_ERROR,
];

const SHOULD_TRY_AGAIN_ERRORS = [
    ERROR_CODES.NOT_ENOUGH_STOCK_FOR_ORDER,
    ERROR_CODES.CART_NOT_FOUND_ERROR,
    ERROR_CODES.MP_PAYMENT_NOT_APPROVED,
    ERROR_CODES.MAX_INSTALLMENTS_ERROR,
    ERROR_CODES.INVALID_COUPON_ERROR,
    ERROR_CODES.COUPON_EXPIRED_ERROR,
];

const GO_TO_HOME_ERRORS = [
    ERROR_CODES.UNKNOWN_ERROR,
    ERROR_CODES.ORDER_VALIDATION_ERROR,
    ERROR_CODES.SELLING_DISABLED_ERROR,
];

module.exports = {
    ERROR_CODES,
    GO_TO_CART_ERRORS,
    SHOULD_TRY_AGAIN_ERRORS,
    GO_TO_HOME_ERRORS,
};
