import React, { useEffect, useState, useMemo } from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { CoolerWidths, ProductTypes } from '../../../../helpers/types';
import BaseProductMultiSelect from '../BaseProductMultiSelect';

const CaseFanSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed,
}) => {
    const initialSlots = useMemo(() => ({
        fan_slots_120: buildSpecs.case?.specs.fan_slots_120,
        fan_slots_140: buildSpecs.case?.specs.fan_slots_140,
        fan_slots_240: buildSpecs.case?.specs.fan_slots_240,
        fan_slots_280: buildSpecs.case?.specs.fan_slots_280,
        fan_slots_360: buildSpecs.case?.specs.fan_slots_360,
    }), [buildSpecs.case]);

    const [maxCaseFanQuantity, setMaxCaseFanQuantity] = useState(null);
    const [remainingFanSlot, setRemainingFanSlot] = useState(initialSlots);
    const [openAlertFan120, setOpenAlertFan120] = useState(false);
    const [openAlertFan140, setOpenAlertFan140] = useState(false);

    const countMaxCaseFanQuantity = (caseFans) => {
        let fan120 = 0;
        let fan140 = 0;
        Object.entries(caseFans).forEach(([key, value]) => {
            if (value && key === 'fan_slots_120') {
                fan120 += value;
            }
            if (value && key === 'fan_slots_140') {
                fan140 += value;
            }
            if (value && key === 'fan_slots_240') {
                fan120 += (value * 2);
            }
            if (value && key === 'fan_slots_280') {
                fan140 += (value * 2);
            }
            if (value && key === 'fan_slots_360') {
                fan120 += (value * 3);
            }
        });
        return { fan120, fan140, totalFan120: (fan120 + fan140) };
    };

    const checkIfCpuCoolerExists = () => {
        if (buildSpecs.cpu_cooler?.specs.case_radiator) {
            const fanCpuCooler = `fan_slots_${buildSpecs.cpu_cooler.specs.case_radiator_width}`;
            if (initialSlots[fanCpuCooler]) {
                return {
                    ...initialSlots,
                    [fanCpuCooler]: initialSlots[fanCpuCooler] - 1,
                };
            }
            if (fanCpuCooler === 'fan_slots_120') {
                if (initialSlots.fan_slots_140) {
                    return {
                        ...initialSlots,
                        fan_slots_140: initialSlots.fan_slots_140 - 1,
                    };
                }
                if (initialSlots.fan_slots_240) {
                    return {
                        ...initialSlots,
                        fan_slots_240: initialSlots.fan_slots_240 - 1,
                        fan_slots_120: 1,
                    };
                }
                if (initialSlots.fan_slots_280) {
                    return {
                        ...initialSlots,
                        fan_slots_280: initialSlots.fan_slots_280 - 1,
                        fan_slots_140: 1,
                    };
                }
                if (initialSlots.fan_slots_360) {
                    return {
                        ...initialSlots,
                        fan_slots_360: initialSlots.fan_slots_360 - 1,
                        fan_slots_240: 1,
                    };
                }
            }
            if (fanCpuCooler === 'fan_slots_240') {
                if (initialSlots.fan_slots_280) {
                    return {
                        ...initialSlots,
                        fan_slots_280: initialSlots.fan_slots_280 - 1,
                    };
                }
                if (initialSlots.fan_slots_360) {
                    return {
                        ...initialSlots,
                        fan_slots_360: initialSlots.fan_slots_360 - 1,
                        fan_slots_120: initialSlots.fan_slots_120
                            ? initialSlots.fan_slots_120 + 1
                            : 1,
                    };
                }
            }
            if (fanCpuCooler === 'fan_slots_140') {
                if (initialSlots.fan_slots_280) {
                    return {
                        ...initialSlots,
                        fan_slots_280: initialSlots.fan_slots_280 - 1,
                        fan_slots_140: 1,
                    };
                }
            }
        }
        return { ...initialSlots };
    };

    const countFan120Quantity = (caseFans) => {
        let quantity = 0;
        caseFans.forEach((item) => {
            if (item.specs.width === CoolerWidths.W_120) {
                quantity += item.quantity;
            }
        });
        return quantity;
    };

    const countFan140Quantity = (caseFans) => {
        let quantity = 0;
        caseFans.forEach((item) => {
            if (item.specs.width === CoolerWidths.W_140) {
                quantity += item.quantity;
            }
        });
        return quantity;
    };

    const validateCaseFans = (caseFans) => {
        const quantityFan120 = countFan120Quantity(caseFans);
        const quantityFan140 = countFan140Quantity(caseFans);
        if (maxCaseFanQuantity?.totalFan120 < quantityFan120) {
            setOpenAlertFan120(true);
            return false;
        }
        if (maxCaseFanQuantity?.fan140 < quantityFan140) {
            setOpenAlertFan140(true);
            return false;
        }
        return true;
    };

    const updateCaseFanMaxAndRemainingQuantities = (quantityFan120, quantityFan140) => {
        let newMaxCaseFanQuantity = { ...maxCaseFanQuantity };
        let newRemainingFanSlot = { ...remainingFanSlot };
        if (!maxCaseFanQuantity) {
            newRemainingFanSlot = checkIfCpuCoolerExists();
            newMaxCaseFanQuantity = countMaxCaseFanQuantity(newRemainingFanSlot);
            setRemainingFanSlot(newRemainingFanSlot);
        }
        if (newMaxCaseFanQuantity.fan120 - quantityFan120 < 0) {
            const difference = newMaxCaseFanQuantity.fan120 - quantityFan120;
            const newFan140 = countMaxCaseFanQuantity(newRemainingFanSlot).fan140 + difference;
            newMaxCaseFanQuantity = {
                ...newMaxCaseFanQuantity,
                fan140: newFan140,
            };
        } else {
            newMaxCaseFanQuantity = {
                ...newMaxCaseFanQuantity,
                fan140: countMaxCaseFanQuantity(newRemainingFanSlot).fan140,
            };
        }
        const newTotalFan120 = countMaxCaseFanQuantity(newRemainingFanSlot).fan140 - quantityFan140;
        setMaxCaseFanQuantity({
            ...newMaxCaseFanQuantity,
            totalFan120: newMaxCaseFanQuantity.fan120 + newTotalFan120,
        });
    };

    useEffect(() => {
        const quantityFan120 = countFan120Quantity(buildSpecs.case_fan);
        const quantityFan140 = countFan140Quantity(buildSpecs.case_fan);
        updateCaseFanMaxAndRemainingQuantities(quantityFan120, quantityFan140);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildSpecs.case_fan]);

    const validateNextStepAllowed = () => {
        setGoToNextAllowed(true);
    };

    const validateQuantity = ({ product, quantity }) => {
        if (product.specs.width === CoolerWidths.W_120
            && quantity > maxCaseFanQuantity?.totalFan120) {
            throw new Error(`Con tu selección de componentes podés agregar hasta ${maxCaseFanQuantity?.totalFan120} case fan de 120mm`);
        }
        if (product.specs.width === CoolerWidths.W_140 && quantity > maxCaseFanQuantity?.fan140) {
            throw new Error(`Con tu selección de componentes podés agregar hasta ${maxCaseFanQuantity?.fan140} case fan de 140mm`);
        }
    };

    return (
        <>
            <BaseProductMultiSelect
                buildProduct={buildSpecs.case_fan}
                validateProducts={validateCaseFans}
                buildSpecs={buildSpecs}
                updateBuildSpecs={updateBuildSpecs}
                validateNextStepAllowed={validateNextStepAllowed}
                productType={ProductTypes.CASE_FAN}
                validateQuantity={validateQuantity}
            />
            <Snackbar
                open={openAlertFan120}
                autoHideDuration={5000}
                onClose={() => setOpenAlertFan120(false)}
            >
                <Alert severity="error" variant="filled">
                    No puedes seleccionar una cantidad de case fan de 120mm mayor a la
                    capacidad máxima del Gabinete (
                    {maxCaseFanQuantity?.totalFan120}
                    )
                </Alert>
            </Snackbar>
            <Snackbar
                open={openAlertFan140}
                autoHideDuration={5000}
                onClose={() => setOpenAlertFan140(false)}
            >
                <Alert severity="error" variant="filled">
                    No puedes seleccionar una cantidad de case fan de 140mm mayor a la
                    capacidad máxima del Gabinete (
                    {maxCaseFanQuantity?.totalFan120}
                    )
                </Alert>
            </Snackbar>
        </>
    );
};

export default CaseFanSelect;
