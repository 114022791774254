import React from 'react';
import {
    Box,
    Grid,
} from '@material-ui/core';
import SpecsProduct from './SpecsProduct';
import DescriptionProduct from './DescriptionProduct';

const ExtendedProductInfo = ({ build }) => {
    const specs = {
        ...build.specs,
        brand: build.brand,
    };

    return (
        <Box py={2} flexGrow={1}>
            <Grid container>
                <Grid item xs={12}>
                    <DescriptionProduct product={build} />
                    <SpecsProduct specs={specs} />
                </Grid>
            </Grid>
        </Box>
    );
};
export default ExtendedProductInfo;
