import React from 'react';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {
    Link,
    useLocation,
} from 'react-router-dom';
import Popover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import Hidden from '@material-ui/core/Hidden';
import DropdownCart from './resources/checkout/components/DropdownCart';

const useStyles = makeStyles((theme) => ({
    containerDropdown: {
        width: '366px',
    },
}));
const pathToHidden = ['/checkout', '/completeOrder'];

export default function GDCart({ totalCartItems }) {
    const location = useLocation();
    const shouldDisplayDropdown = pathToHidden.includes(location.pathname) || (totalCartItems < 1);
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-Popover',
    });

    return (
        <>
            <IconButton
                component={Link}
                to="/checkout"
                aria-label="show 4 products"
                color="inherit"
                {...bindHover(popupState)}
            >
                <Badge badgeContent={totalCartItems} color="secondary" overlap="rectangular">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
            {shouldDisplayDropdown ? null
                : (
                    <Hidden xsDown>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            disableRestoreFocus
                        >
                            <div
                                className={classes.containerDropdown}
                            >
                                <DropdownCart />
                            </div>
                        </Popover>
                    </Hidden>
                )}
        </>
    );
}
