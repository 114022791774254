import {
    CoolerWidths,
    CpuBrands,
    CpuSockets,
    StorageInterfaces,
    MotherFormFactors,
    ProductTypes,
    RamStickTypes,
} from './types';

const sockets = [
    { id: CpuSockets.AM4, name: 'AMD AM4' },
    { id: CpuSockets.AM5, name: 'AMD AM5' },
    { id: CpuSockets.TRX4, name: 'AMD TRX4' },
    { id: CpuSockets.LGA1151, name: 'Intel 1151' },
    { id: CpuSockets.LGA1200, name: 'Intel 1200' },
    { id: CpuSockets.LGA2011, name: 'Intel 2011' },
];
const boolean = [
    { id: true, name: 'Si' },
    { id: false, name: 'No' },
];

const brands = [
    { id: CpuBrands.INTEL, name: 'Intel' },
    { id: CpuBrands.AMD, name: 'AMD' },
];

const productTypes = [
    { id: ProductTypes.MOTHER, name: 'Mother' },
    { id: ProductTypes.CPU, name: 'CPU' },
    { id: ProductTypes.CPU_COOLER, name: 'CPU Cooler' },
    { id: ProductTypes.RAM, name: 'RAM' },
    { id: ProductTypes.GPU, name: 'GPU' },
    { id: ProductTypes.STORAGE, name: 'Storage' },
    { id: ProductTypes.CASE, name: 'Case' },
    { id: ProductTypes.CASE_FAN, name: 'Case fan' },
    { id: ProductTypes.POWER, name: 'Power' },
    { id: ProductTypes.MONITOR, name: 'Monitor' },
    { id: ProductTypes.MOUSE, name: 'Mouse' },
    { id: ProductTypes.KEYBOARD, name: 'Keyboard' },
    { id: ProductTypes.HEADSET, name: 'Headset' },
    { id: ProductTypes.MICROPHONE, name: 'Microphones' },
    { id: ProductTypes.CHAIR, name: 'Chair' },
    { id: ProductTypes.ACCESSORIES, name: 'Accessories' },
    { id: ProductTypes.SPEAKERS, name: 'Speakers' },
    { id: ProductTypes.FURNITURE, name: 'Furniture' },
    { id: ProductTypes.OPERATIVE_SYSTEM, name: 'Operative System' },
    { id: ProductTypes.OTHER, name: 'Other' },
    { id: ProductTypes.BUILD, name: 'Pc gamer' },
    { id: ProductTypes.NOTEBOOKS, name: 'Notebooks' },
];

const ramStickTypes = [
    { id: RamStickTypes.DDR_3, name: 'DDR3' },
    { id: RamStickTypes.DDR_4, name: 'DDR4' },
    { id: RamStickTypes.DDR_5, name: 'DDR5' },
];

const storageInterfaces = [
    { id: StorageInterfaces.M2, name: 'M2' },
    { id: StorageInterfaces.SATA_3, name: 'SATA3' },
];

const radiatorWidth = [
    { id: '240', name: '240mm' },
    { id: '360', name: '360mm' },
];

const motherFormFactors = [
    { id: MotherFormFactors.STANDARD_ATX, name: 'Standard-ATX' },
    { id: MotherFormFactors.MICRO_ATX, name: 'Micro-ATX' },
    { id: MotherFormFactors.MINI_ITX, name: 'Mini-ITX' },
    { id: MotherFormFactors.NANO_ITX, name: 'Nano-ITX' },
    { id: MotherFormFactors.PICO_ITX, name: 'Pico-ITX' },
];

const coolerWidths = [
    { id: CoolerWidths.W_120, name: '120mm' },
    { id: CoolerWidths.W_140, name: '140mm' },
    { id: CoolerWidths.W_240, name: '240mm' },
    { id: CoolerWidths.W_280, name: '280mm' },
    { id: CoolerWidths.W_360, name: '360mm' },
];

export default {
    brand: brands,
    cpu_brand: brands,
    case_fan_width: coolerWidths,
    case_radiator_width: coolerWidths,
    cpu_cooler: boolean,
    has_cooler: boolean,
    case_radiator: boolean,
    video_output: boolean,
    igpu: boolean,
    mother_form_factor: motherFormFactors,
    product_types: productTypes,
    radiator_width: radiatorWidth,
    ram_stick_type: ramStickTypes,
    stick_type: ramStickTypes,
    cpu_socket: sockets,
    socket: sockets,
    sockets,
    storage_interface: storageInterfaces,
};
