import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Button, Hidden, Typography,
} from '@material-ui/core';

import { CPUSelect, defaultCpuBrandValue } from './steps/CPUBrandSelect';
import { defaultBudget } from './steps/BudgetSelect/constants';
import { GameSelect, defaultGames } from './steps/GameSelect';
import HardwareSelect from './steps/HardwareSelect';
import PriceSelect from './steps/BudgetSelect';
import RecommendedBuildList from './steps/RecommendedBuildList/RecommendedBuildList';
import PageContent from '../../components/PageContent';
import Step from '../../components/Step';
import colors from '../../../theme/colors';
import GDTheme from '../../../theme/GDTheme';
import { helmetEasyBuilderConfig } from '../../../data/seo';
import {
    sendEasyBuilderSelectBrandGAEvent, sendEasyBuilderSelectBudgetGAEvent,
    sendEasyBuilderSelectGamesGAEvent, sendPageViewGAEvent,
} from '../../../helpers/gaHelper';

const useStyles = makeStyles(() => ({
    flexContainer: {
        '@media (max-width: 959px)': {
            flexDirection: 'column',
        },
    },
    steps: {
        background: colors.black,
        color: colors.white,
    },
    stepsDesktop: {
        width: 300,
        height: 505,
    },
    stepsItems: {
        display: 'flex',
        marginTop: 32,
        padding: 0,
        justifyContent: 'center',
    },
    stepItems: {
        marginLeft: 12,
    },
    stepItem: {
        marginTop: 2,
        marginBottom: 45,
        textAlign: 'left',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    stepButton: {
        color: '#727272',
        textTransform: 'none',
        fontSize: 12,
    },
    subtitle: {
        fontSize: 12,
    },
    stepChangeButton: {
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
    stepsMobile: {
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 665px)': {
            justifyContent: 'flex-start',
        },
    },
}));

const BuildSpecs = {
    budget: defaultBudget,
    cpuBrand: defaultCpuBrandValue,
    games: defaultGames,
};

const EasyBuilder = () => {
    const [actualStep, setActualStep] = useState(0);
    const [buildSpecs, setBuildSpecs] = useState(BuildSpecs);
    const myRef = useRef(null);

    let steps;

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    const classes = useStyles();
    const updateBuildSpecs = (newSpecs) => {
        const all = { ...buildSpecs, ...newSpecs };
        setBuildSpecs(all);
    };

    const goToFirstStep = () => {
        setBuildSpecs(BuildSpecs);
        setActualStep(0);
    };
    const goToNextStep = () => {
        steps[actualStep].onNextStep?.();
        setActualStep(actualStep + 1);
    };
    const goToPreviousStep = () => {
        if (actualStep !== 1) {
            setActualStep(actualStep - 1);
        } else {
            setBuildSpecs(BuildSpecs);
            setActualStep(actualStep - 1);
        }
    };

    const handleClick = (newStep) => setActualStep(newStep);

    steps = [
        {
            name: 'Juegos',
            title: '¿A qué jugás?',
            content: (
                <GameSelect
                    updateBuildSpecs={updateBuildSpecs}
                    buildSpecs={buildSpecs}
                />
            ),
            handleOmitStep: (() => {
                updateBuildSpecs({ games: defaultGames });
                goToNextStep();
            }),
            onNextStep: () => {
                sendEasyBuilderSelectGamesGAEvent(buildSpecs.games);
            },
        },
        {
            name: 'Marca',
            title: '¿Qué marca preferís?',
            content: (
                <CPUSelect
                    updateBuildSpecs={updateBuildSpecs}
                    buildSpecs={buildSpecs}
                />
            ),
            handleOmitStep: (() => {
                updateBuildSpecs({ cpuBrand: defaultCpuBrandValue });
                goToNextStep();
            }),
            onNextStep: () => {
                sendEasyBuilderSelectBrandGAEvent(buildSpecs.cpuBrand);
            },
        },
        {
            name: 'Tipo de Hardware',
            title: '¿Qué tipo de Hardware querés?',
            content: (
                <HardwareSelect />
            ),
            handleOmitStep: (goToNextStep),
        },
        {
            name: 'Presupuesto',
            title: '¿Cuál es tu presupuesto?',
            content: (
                <Box style={{ minHeight: 307 }}>
                    <PriceSelect
                        updateBuildSpecs={updateBuildSpecs}
                        buildSpecs={buildSpecs}
                    />
                </Box>
            ),
            handleOmitStep: (() => {
                updateBuildSpecs({ budget: defaultBudget });
                goToNextStep();
            }),
            onNextStep: () => {
                sendEasyBuilderSelectBudgetGAEvent(buildSpecs.budget);
            },
        },
        {
            name: 'Resultados',
            title: 'Resultados recomendados',
            content: (<RecommendedBuildList
                goToFirstStep={goToFirstStep}
                buildSpecs={buildSpecs}
            />),
            handleOmitStep: (() => { }),
        },
    ];

    const renderStepButtons = steps.map((step, index) => (
        <Box className={classes.stepItem} key={step.name}>
            <Button
                variant="text"
                disabled={actualStep === index}
                className={classes.stepButton}
                onClick={() => handleClick(index)}
            >
                {step.name}
            </Button>
        </Box>
    ));

    const isLastStep = actualStep === steps.length - 1;

    return (
        <PageContent breadcrumb="Easy Builder">
            <Helmet>
                <title>{helmetEasyBuilderConfig.title}</title>
                <meta property="og:title" content={helmetEasyBuilderConfig.title} />
                <meta name="description" content={helmetEasyBuilderConfig.description} />
                <meta name="og:description" content={helmetEasyBuilderConfig.description} />
            </Helmet>
            <Box display="flex" mt={0} align="center" className={classes.flexContainer}>
                <Hidden mdUp>
                    <Box p={2} className={classes.steps}>
                        <Typography variant="h1" style={{ fontSize: 18, lineHeight: '22px' }}>Easy Builder</Typography>
                        <Box className={classes.stepsMobile} display="flex" mt={2}>
                            {steps.map((step, index) => (
                                <Step
                                    key={step.name}
                                    step={step}
                                    index={index}
                                    actualStep={actualStep}
                                    isFinished={buildSpecs[index] !== null}
                                    myRef={myRef}
                                    showName
                                    isLast={index === steps.length - 1}
                                    onClick={setActualStep}
                                />
                            ))}
                        </Box>
                    </Box>
                </Hidden>
                <Hidden smDown>
                    <Box style={{ flexBasis: 0 }}>
                        <Box className={`${classes.steps} ${classes.stepsDesktop}`} px={4}>
                            <Box pt={3} pb={2}>
                                <Typography variant="h1" style={{ fontSize: 18, lineHeight: '22px' }}>
                                    Easy Builder
                                </Typography>
                            </Box>
                            <Typography className={classes.subtitle}>
                                En pocos pasos elegí tus preferencias,
                                nosotros armamos la PC ideal para vos
                            </Typography>
                            <Box className={classes.stepsItems}>
                                <img src={`/images/easy-builder/step${actualStep}.svg`} height={347} alt={`Paso actual: ${actualStep}`} />
                                <Box className={classes.stepItems}>
                                    {renderStepButtons}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Hidden>
                <Box mt={2} mx={3} flexGrow={1}>
                    <Box mb={2}>
                        <Typography variant="h2">
                            {steps[`${actualStep}`].title}
                        </Typography>
                    </Box>
                    {steps[`${actualStep}`].content}

                    <Box>
                        {
                            actualStep !== 0
                                && !isLastStep
                                ? (
                                    <Button variant="outlined" onClick={goToPreviousStep} className={classes.stepChangeButton}>
                                        Anterior
                                    </Button>
                                )
                                : (<></>)
                        }
                        {
                            !isLastStep
                            && (
                                <Button variant="contained" onClick={goToNextStep} className={classes.stepChangeButton}>
                                    Siguiente
                                </Button>
                            )

                        }
                    </Box>
                    {
                        actualStep !== steps.length - 1
                            ? (
                                <Button variant="text" onClick={steps[`${actualStep}`].handleOmitStep}>
                                    Omitir paso
                                </Button>
                            )
                            : (<></>)
                    }
                </Box>
            </Box>
        </PageContent>
    );
};
export default EasyBuilder;
