const INVOICE_TYPES = {
    finalConsumer: 'finalConsumer',
    invoiceA: 'invoiceA',
};

const IVA_CONDITIONS = [
    { name: 'IVA Responsable Inscripto', value: 'ivaResponsableInscripto' },
    { name: 'Monotributista Trabajador Independiente Promovido', value: 'monotributistaIndependientePromovido' },
    { name: 'Monotributista Social', value: 'monotributistaSocial' },
    { name: 'Responsable Monotributo', value: 'responsableMonotributo' },
];

const SHIPPING_TYPES = {
    homeDelivery: 'homeDelivery',
    pickupAtNearestAgency: 'pickupAtNearestAgency',
    pickupAtWarehouse: 'pickupAtWarehouse',
};

const DEFAULT_CENTER = { lat: -37.98, lng: -63.36 };

const EMAIL = 'hola@gamerfactory.com.ar';

export {
    EMAIL,
    DEFAULT_CENTER,
    INVOICE_TYPES,
    IVA_CONDITIONS,
    SHIPPING_TYPES,
};
