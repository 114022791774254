import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ProductTypes, StorageInterfaces } from '../../../../helpers/types';
import BaseProductMultiSelect from '../BaseProductMultiSelect';

const StorageSelect = ({
    updateBuildSpecs, buildSpecs, setGoToNextAllowed,
}) => {
    const [openAlertSata3, setOpenAlertSata3] = useState(false);
    const [openAlertM2, setOpenAlertM2] = useState(false);

    const countSata3Quantity = (storages) => {
        let quantity = 0;
        storages.forEach((storage) => {
            if (storage.specs.interface === StorageInterfaces.SATA3) {
                quantity += storage.quantity;
            }
        });
        return quantity;
    };

    const countM2Quantity = (storages) => {
        let quantity = 0;
        storages.forEach((storage) => {
            if (storage.specs.interface === StorageInterfaces.M2) {
                quantity += storage.quantity;
            }
        });
        return quantity;
    };

    const validateStorages = (storages) => {
        const quantitySata3 = countSata3Quantity(storages);
        const quantityM2 = countM2Quantity(storages);

        if (quantitySata3 > buildSpecs.mother.specs.sata3_slots) {
            setOpenAlertSata3(true);
            return false;
        }
        if (quantityM2 > buildSpecs.mother.specs.m2_slots) {
            setOpenAlertM2(true);
            return false;
        }

        setOpenAlertM2(false);
        setOpenAlertSata3(false);
        return true;
    };

    const validateQuantity = ({ product, quantity }) => {
        if (product.specs.interface === StorageInterfaces.SATA3
                && quantity > buildSpecs.mother.specs.sata3_slots) {
            throw new Error(`Con tu selección de componentes podés agregar hasta
                    ${buildSpecs.mother.specs.sata3_slots} unidades de discos tipo SATA3`);
        }
        if (product.specs.interface === StorageInterfaces.M2
                && quantity > buildSpecs.mother.specs.m2_slots) {
            throw new Error(`Con tu selección de componentes podés agregar hasta
                    ${buildSpecs.mother.specs.m2_slots} unidades de discos tipo M2`);
        }
    };

    const validateNextStepAllowed = () => {
        setGoToNextAllowed(buildSpecs.internal_storage && buildSpecs.internal_storage.length > 0);
    };

    return (
        <>
            <Snackbar
                open={openAlertSata3}
                autoHideDuration={5000}
                onClose={() => setOpenAlertSata3(false)}
            >
                <Alert severity="error" variant="filled">
                    No puedes seleccionar una cantidad de discos tipo SATA3 mayor a la capacidad
                    máxima del Motherboard
                </Alert>
            </Snackbar>
            <Snackbar
                open={openAlertM2}
                autoHideDuration={5000}
                onClose={() => setOpenAlertM2(false)}
            >
                <Alert severity="error" variant="filled">
                    No puedes seleccionar una cantidad de discos tipo M2 mayor a la capacidad
                    máxima del Motherboard
                </Alert>
            </Snackbar>
            <BaseProductMultiSelect
                buildProduct={buildSpecs.internal_storage}
                validateProducts={validateStorages}
                buildSpecs={buildSpecs}
                updateBuildSpecs={updateBuildSpecs}
                validateNextStepAllowed={validateNextStepAllowed}
                productType={ProductTypes.INTERNAL_STORAGE}
                validateQuantity={validateQuantity}
            />
        </>
    );
};

export default StorageSelect;
