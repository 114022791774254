import React from 'react';
import {
    Box, Grid, Hidden,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ProductsSkeleton = ({ showSortSkeleton = true, marginLeft = 3 }) => {
    const ProductSkeleton = () => (
        <Skeleton variant="rect" height={328} />
    );
    const SortSkeleton = () => (
        <Box ml={4} mr={2} pt={1} pb={5.5} display="flex" justifyContent="flex-end">
            <Skeleton variant="rect" width={180} height={16} />
        </Box>
    );
    const gridItemProps = {
        item: true, xs: 6, sm: 4, md: 4, lg: 3, xl: 2,
    };
    return (
        <>
            { showSortSkeleton && <SortSkeleton /> }
            <Box ml={{ sm: 0, md: marginLeft }}>
                <Grid container spacing={2}>
                    <Grid {...gridItemProps}>
                        <ProductSkeleton />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <ProductSkeleton />
                    </Grid>
                    <Hidden xsDown>
                        <Grid {...gridItemProps}>
                            <ProductSkeleton />
                        </Grid>
                        <Grid {...gridItemProps}>
                            <ProductSkeleton />
                        </Grid>
                        <Hidden smDown>
                            <Grid {...gridItemProps}>
                                <ProductSkeleton />
                            </Grid>
                            <Grid {...gridItemProps}>
                                <ProductSkeleton />
                            </Grid>
                            <Hidden mdDown>
                                <Grid {...gridItemProps}>
                                    <ProductSkeleton />
                                </Grid>
                                <Grid {...gridItemProps}>
                                    <ProductSkeleton />
                                </Grid>
                            </Hidden>
                        </Hidden>
                    </Hidden>
                </Grid>
            </Box>
        </>
    );
};

export default ProductsSkeleton;
