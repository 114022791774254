import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Box,
    RadioGroup,
    Radio,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Edit, HighlightOffOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import cartHelper from '../../../helpers/cartHelper';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import API from '../../../api/API';
import handler from '../../../helpers/handler';
import { ProductsContext } from '../../../contexts/Products/context';
import imageHelper from '../../../helpers/imageHelper';
import proBuildHelper from '../../../helpers/proBuildHelper';
import { ProductTypes } from '../../../helpers/types';
import { sendAddToCartGAEvent, sendRemoveFromCartGAEvent } from '../../../helpers/gaHelper';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    itemDescription: {
        color: '#1C1C1C',
        marginBottom: 8,
    },
    itemCode: {
        color: '#727272',
        marginBottom: 8,
    },
    noOutlineButton: {
        border: 'none',
        outline: 'none',
        paddingLeft: 0,
    },
    actionButtons: {
        display: 'flex',
    },
    deleteButtonIcon: {
        fill: '#EA4335',
    },
    itemImage: {
        maxWidth: 'min(107px,90%)',
        maxHeight: 105,
    },
    radioGroup: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
    },
    radioLabel: {
        marginRight: 0,
    },
    radio: {
        paddingRight: 2,
        paddingLeft: 4,
    },
}));

const ItemData = ({
    item, deleteItem,
}) => {
    const classes = useStyles();
    const [selectedSOValue, setSelectedSOValue] = React.useState(item.withLicense ? 'withLicense' : 'withoutLicense');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const { productsDispatch } = useContext(ProductsContext);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (item.SO) {
            setSelectedSOValue('withLicense');
        }
    }, [item]);

    const handleChangeSelectedSOValue = async (event) => {
        const newOSValue = event.target.value;
        API.products.getOperativeSystems()
            .then((res) => {
                const operativeSystem = res.data[0];
                const newCartItems = cartHelper.toggleOperativeSystemFrom({
                    operativeSystem,
                    cartItems: state.user.cart.items,
                    buildId: item.buildId || item.buildSubItemIndex,
                });
                const osForGA = {
                    item_id: operativeSystem.id,
                    item_name: operativeSystem.name,
                    item_category: ProductTypes.OPERATIVE_SYSTEM,
                    price: operativeSystem.price.special.ARS,
                    quantity: 1,
                };
                if (item.buildId) osForGA.for_probuild_id = item.buildId;
                if (newOSValue === 'withLicense') sendAddToCartGAEvent([osForGA]);
                else sendRemoveFromCartGAEvent([osForGA]);

                if (Array.isArray(newCartItems)) {
                    setSelectedSOValue(newOSValue);
                    const cartToSend = state.user.cart;
                    cartToSend.items = cartHelper.parseProductsToSend(newCartItems);
                    setLoading(true);
                    API.carts.updateCart(cartToSend)
                        .then((response) => {
                            dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                            setLoading(false);
                        })
                        .catch((error) => {
                            handler.handleError({
                                error, userContextDispatch: dispatch, enqueueSnackbar,
                            });
                        });
                }
            })
            .catch((error) => {
                handler.handleError({
                    error, userContextDispatch: dispatch, enqueueSnackbar,
                });
            });
    };

    const customizeBuild = async () => {
        sendRemoveFromCartGAEvent([{
            item_id: item.id,
            item_name: item.name,
            item_category: item.type,
            price: item.price.special,
            quantity: item.quantity || 1,
        }]);
        if (item.buildId) {
            const productsArray = item.products.filter(
                (product) => product.buildId === item.buildId,
            );
            const productsArrayWithProps = await Promise.all(
                productsArray.map((product) => API.products
                    .search({ filter: { id: product.id } })
                    .then((res) => {
                        const productWithProps = res.data.data[0];
                        return { ...productWithProps, quantity: product.quantity || 1 };
                    })),
            );
            productsDispatch({
                type: 'CREATE_BUILD',
                products: proBuildHelper.formatProductsArrayToBuild(productsArrayWithProps),
            });
            history.push(`/proBuilder/${item.buildId}`);
        } else {
            API.products.search({
                filter: { id: item.id, type: 'build' },
            })
                .then((response) => {
                    const build = response.data.data[0];
                    productsDispatch({
                        type: 'CREATE_BUILD_FROM_EASY_BUILDER',
                        products: proBuildHelper.getProductsFromBuild(build),
                    });
                    history.push(`/proBuilder/${build.name}`);
                }).catch((error) => {
                    handler.handleError({
                        error, userContextDispatch: dispatch, enqueueSnackbar,
                    });
                });
        }
    };
    const handleDeleteItem = () => {
        deleteItem();
    };

    if (loading) {
        return (
            <CircularProgress />
        );
    }
    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xs={3}>
                <img
                    src={imageHelper.getProductImageSrc(item)}
                    alt={imageHelper.getProductImageAlt(item)}
                    className={classes.itemImage}
                />
            </Grid>
            <Grid item xs={9}>
                <Typography className={classes.itemDescription} variant="h6">
                    {item.name}
                </Typography>
                <Typography className={classes.itemCode} variant="subtitle2">
                    {`Código del producto: ${item.code}`}
                </Typography>
                {item.buildId || item.type === 'build' ? (
                    <Box>
                        <RadioGroup
                            name="license"
                            value={selectedSOValue}
                            onChange={handleChangeSelectedSOValue}
                            className={classes.radioGroup}
                        >
                            <FormControlLabel
                                classes={{ root: classes.radioLabel }}
                                style={{ paddingRight: 16 }}
                                value="withoutLicense"
                                control={<Radio size="small" classes={{ root: classes.radio }} color="primary" />}
                                label={<Typography style={{ marginRight: 8 }} variant="subtitle2">Sin licencia Windows 11 Home  </Typography>}
                            />
                            <FormControlLabel
                                classes={{ root: classes.radioLabel }}
                                value="withLicense"
                                control={<Radio size="small" classes={{ root: classes.radio }} color="primary" />}
                                label={<Typography variant="subtitle2">Con licencia Windows 11 Home </Typography>}
                            />
                        </RadioGroup>
                    </Box>
                ) : ''}

                <FormControl fullWidth>
                    <Grid container>
                        <Grid className={classes.actionButtons}>
                            <FormControl fullWidth>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={handleDeleteItem}
                                    startIcon={(
                                        <HighlightOffOutlined
                                            className={classes.deleteButtonIcon}
                                        />
                                    )}
                                    className={classes.noOutlineButton}
                                >
                                    Quitar
                                </Button>
                            </FormControl>
                            {(item.customizable) && (item.buildId || item.type === 'build') && (
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    startIcon={<Edit />}
                                    className={classes.noOutlineButton}
                                    onClick={customizeBuild}
                                >
                                    Personalizar
                                </Button>
                            )}

                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ItemData;
