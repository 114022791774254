import React from 'react';
import { Typography } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';

import { useTreeItemStyles } from './styles';

const StyledTreeItem = (props) => {
    const classes = useTreeItemStyles();
    const { labelText, ...other } = props;

    return (
        <TreeItem
            label={(
                <div className={classes.labelRoot}>
                    <Typography variant="body1" className={classes.labelText}>
                        {labelText}
                    </Typography>
                </div>
            )}
            classes={{
                content: classes.content,
            }}
            {...other}
        />
    );
};

export default StyledTreeItem;
