import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import colors from '../../theme/colors';
import TextBlock, { StyledTitle, StyledTypography } from '../components/TextBlock';

import {
    purchasesTexts,
    paymentsTexts,
    shipmentsTexts,
    warrantyTexts,
    regretConditions,
    regretMoneyConditions,
} from './constants';
import { helmetFAQConfig } from '../../data/seo';
import FaqRegretPolicy from './FaqRegretPolicy';
import FaqDeleteAccount from './FaqDeleteAccount';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 237px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
        background: colors.black,
        color: colors.white,
    },
    container: {
        paddingTop: 24,
        paddingBottom: 24,
    },
}));

const FAQ = () => {
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>{helmetFAQConfig.title}</title>
                <meta property="og:title" content={helmetFAQConfig.title} />
                <meta name="description" content={helmetFAQConfig.description} />
                <meta name="og:description" content={helmetFAQConfig.description} />
            </Helmet>
            <Box className={classes.root}>
                <Container maxWidth="xl" className={classes.container}>
                    <Typography
                        variant="h1"
                        align="center"
                        style={{
                            marginBottom: '12px', color: colors.white, fontSize: '20px', lineHeight: '24px',
                        }}
                    >
                        Preguntas frecuentes
                    </Typography>

                    <TextBlock blockTitle="Compras" blockTexts={purchasesTexts} />
                    <TextBlock blockTitle="Pagos" blockTexts={paymentsTexts} />
                    <TextBlock blockTitle="Envíos" blockTexts={shipmentsTexts} />
                    <TextBlock blockTitle="Garantía" blockTexts={warrantyTexts} />
                    <FaqRegretPolicy />
                    <br />
                    <FaqDeleteAccount />
                </Container>
            </Box>
        </>
    );
};
export default FAQ;
