import proBuildHelper from '../../helpers/proBuildHelper';

const productsReducer = (state, action) => {
    switch (action.type) {
    case 'MODIFY_BUILD':
        return {
            ...state,
            [action.product.key]: action.product.productContent,
        };
    case 'CLEAN_PRODUCTS':
        localStorage.setItem('products', JSON.stringify(action.products));
        return {
            ...action.products,
        };
    case 'CREATE_BUILD':
        localStorage.setItem('products', JSON.stringify(action.products));
        return {
            ...action.products,
        };
    case 'DELETE_BUILD':
        localStorage.setItem('products', JSON.stringify(proBuildHelper.buildInitialState));
        return proBuildHelper.buildInitialState;
    case 'UPDATE_BUILD_OUT_OF_STOCK':
        localStorage.setItem('products', JSON.stringify({ ...state, [action.product.key]: null }));
        return {
            ...state,
            [action.product.key]: null,
        };
    case 'CREATE_BUILD_FROM_EASY_BUILDER':
        localStorage.setItem('products', JSON.stringify(action.products));
        return {
            ...action.products,
        };
    default:
        return state;
    }
};

export default productsReducer;
