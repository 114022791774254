import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    TextField,
    Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { Helmet } from 'react-helmet';

import { UserContext, useUser } from '../../contexts';
import userConstants from '../../contexts/User/userConstants';

import API from '../../api/API';

import { CartProductTypes, ProductTypes } from '../../helpers/types';
import { sendItemViewGAEvent, sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import productHelper from '../../helpers/productHelper';
import seoHelper from '../../helpers/seoHelper';

import CarouselPrincipal from './CarouselPrincipal';
import ExtendedProductInfo from './ExtendedProductInfo';
import PageContent from '../components/PageContent';
import ProductInfo from './ProductInfo';
import ProductsCarousel from '../home/ProductsCarousel';

import colors from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        justifyContent: 'space-between',
        '@media (max-width: 599px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    containerDetails: {
        '@media (max-width: 599px)': {
            width: '100%',
        },
        '@media (min-width: 600px)': {
            minWidth: '290px',
        },
    },
    containerImage: {
        marginLeft: '16px',
        width: '100%',
        maxWidth: '550px',
        flexGrow: 1,
    },
    productText: {
        fontWeight: 700,
        fontSize: '20px',
    },
    addButton: {
        marginLeft: 8,
        width: 136,
        height: 40,
    },
    removeButton: {
        marginLeft: 8,
        width: 136,
        height: 40,
        backgroundColor: colors.black,
        color: colors.yellow,
    },
    inputNumber: {
        marginTop: 0,
        color: 'transparent',
        height: '100%',
    },
    inputNumberText: {
        textAlign: 'end',
        textShadow: '0 0 0 #1C1C1C',
    },
    shoppingCartIcon: {
        width: 15,
        height: 16,
        marginRight: 3,
        marginBottom: 2,
    },
    numberInputMultiSelect: {
        width: '38px',
        height: 40,
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        paddingTop: 0,
        textAlign: 'center',
    },
    buttonInput: {
        height: 40,
        minWidth: '38px',
        cursor: 'pointer',
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FDFDFD',
            borderColor: theme.palette.primary.main,
            fontWeight: 700,
        },
        '&:disabled': {
            color: colors.white,
            backgroundColor: '#DBDBD5',
            borderColor: '#DBDBD5',
        },
    },
}));

const ProductDetail = () => {
    const classes = useStyles();
    const history = useHistory();
    const { slug } = useParams();
    const { pathname } = useLocation();
    const [state, dispatch] = useContext(UserContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [moreProducts, setMoreProducts] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [product, setProduct] = useState({
        name: '',
        slug: '',
        code: '',
        description: '',
        id: '',
        images: [],
        price: {
            list: {
                ARS: 0,
                USD: 0,
            },
            special: {
                ARS: 0,
                USD: 0,
            },
        },
        specs: {},
        stock: 0,
        type: '',
        brand: '',
        seo: {
            description: '',
        },
    });
    const [isSelected, setSelected] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState(product.quantity ?? 1);
    const pageTitle = seoHelper.getLimitedLengthPageTitleForProduct(product.name.toUpperCase());

    const isBuild = product.type === ProductTypes.BUILD;

    const {
        cart: {
            hasProduct,
            getItemInCartForProduct,
            addItemOnCart,
            updateItemOnCart,
            removeProductFromCart,
            getBuildQuantity,
        },
    } = useUser();

    useEffect(() => {
        setSelected(hasProduct(product));
        if (hasProduct(product)) {
            if (isBuild) setSelectedQuantity(getBuildQuantity(product) ?? 1);
            else setSelectedQuantity(getItemInCartForProduct(product)?.quantity ?? 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        product,
        hasProduct,
        getItemInCartForProduct,
        getBuildQuantity,
    ]);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        if (product.id) {
            sendItemViewGAEvent({
                item_id: product.id,
                item_name: product.name,
                item_category: product.type,
                price: product.price.special.ARS,
            });
        }
    }, [product]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        API.products.get({ slug: decodeURIComponent(slug) })
            .then((response) => {
                setIsLoaded(true);
                setProduct(response.data);
            }).catch((err) => {
                handler.handleError({
                    enqueueSnackbar,
                    error: err,
                    history,
                    redirectIfNotFound: true,
                    userContextDispatch: dispatch,
                });
                setIsLoaded(true);
                setError(err?.message ?? 'Hubo un error conectándose al servidor');
            });
        API.products.search({
            sort: productHelper.createSortObject(productHelper.SORT_VALUES.popularity),
        })
            .then((response) => {
                setMoreProducts(response.data.data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, dispatch, enqueueSnackbar]);

    const handleChangeQuantity = async (valueToAdd) => {
        let newQuantity = Math.max(valueToAdd + selectedQuantity, 1);
        if (newQuantity > product.stock) {
            enqueueSnackbar(`Disculpa, solo tenemos ${product.stock} unidad/es en stock`, { variant: 'warning' });
            return;
        }
        setSelectedQuantity(newQuantity);
        if (isSelected) {
            if (isBuild && newQuantity < selectedQuantity) newQuantity = null;
            updateItemOnCart({
                ...product,
                price: product.price.special.ARS,
                quantity: newQuantity,
                cartProductType: isBuild ? CartProductTypes.BUILD : CartProductTypes.PRODUCT,
            });
        }
    };

    const handleRemoveProduct = () => {
        setSelected(false);
        removeProductFromCart(product);
    };

    const handleAddProduct = () => {
        if (!state.user?.cart.id) {
            API.carts.post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((err) => {
                    handler.handleError({
                        error: err, userContextDispatch: dispatch, enqueueSnackbar,
                    });
                });
        }
        addItemOnCart({
            ...product,
            price: product.price.special.ARS,
            quantity: selectedQuantity,
            cartProductType: isBuild ? CartProductTypes.BUILD : CartProductTypes.PRODUCT,
        });
    };

    const toggleAddOrRemoveSelection = () => {
        if (!isSelected) handleAddProduct(product);
        else handleRemoveProduct(product);
    };

    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product.name,
        description: product.seo.description,
        sku: product.code,
        brand: {
            '@type': 'Brand',
            name: product.brand,
        },
        image: {
            '@type': 'ImageObject',
            url: product.images[0]?.url,
        },
        offers: {
            '@type': 'Offer',
            availability: product.stock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/SoldOut',
            price: product.price.special.ARS,
            priceCurrency: 'ARS',
            itemCondition: 'https://schema.org/NewCondition',
            url: window.location.href,
        },
    };

    if (error) {
        return (
            <PageContent>
                Error:
                {error}
            </PageContent>
        );
    } if (!isLoaded) {
        return (
            <PageContent>
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </PageContent>
        );
    }
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:title" content={pageTitle} />
                <meta name="description" content={product.seo.description} />
                <meta name="og:description" content={product.seo.description} />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <PageContent>
                <Box
                    display="flex"
                    pt={{
                        xs: 0, sm: 0, md: 2, lg: 2, xl: 2,
                    }}
                    px={{ xs: 2 }}
                    className={classes.flexContainer}
                >

                    <Box className={classes.containerImage}>
                        <CarouselPrincipal images={product.images} label={product.label} />
                    </Box>

                    <Box
                        pl={{
                            xs: 2, sm: 2, md: 5, lg: 5, xl: 5,
                        }}
                        pr={{
                            xs: 2, sm: 0, md: 2, lg: 2, xl: 2,
                        }}
                        className={classes.containerDetails}
                        flexGrow={2}
                    >
                        <ProductInfo item={product} />
                        <Box
                            mt={3}
                            mb={2}
                            align="left"
                        >
                            <Box display="flex" justifyContent="flex-start" mb={1}>
                                <Button
                                    className={classes.buttonInput}
                                    variant="outlined"
                                    onClick={() => handleChangeQuantity(-1)}
                                >
                                    -
                                </Button>
                                <TextField
                                    value={selectedQuantity}
                                    type="number"
                                    disabled
                                    className={classes.numberInputMultiSelect}
                                    InputProps={{
                                        autoComplete: 'no-autocomplete',
                                        inputProps: { min: 1, max: product.stock },
                                        className: classes.inputNumber,
                                        classes: {
                                            input: classes.inputNumberText,
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                                <Button
                                    className={classes.buttonInput}
                                    variant="outlined"
                                    onClick={() => handleChangeQuantity(+1)}
                                    disabled={(product.stock === selectedQuantity)}
                                >
                                    +
                                </Button>
                                <Button
                                    onClick={toggleAddOrRemoveSelection}
                                    variant="contained"
                                    className={isSelected
                                        ? classes.removeButton : classes.addButton}
                                    disabled={(product.stock < selectedQuantity)}
                                >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {isSelected
                                        ? (selectedQuantity > 1 ? `Agregado (${selectedQuantity})` : 'Agregado')
                                        : (
                                            <>
                                                <ShoppingCartRoundedIcon
                                                    className={classes.shoppingCartIcon}
                                                />
                                                Agregar
                                            </>
                                        )}
                                </Button>
                            </Box>

                        </Box>
                    </Box>

                </Box>
                <Box py={2} px={{ xs: 2 }}>
                    <ExtendedProductInfo build={product} />
                    <Box mt={2}>
                        <Typography className={classes.productText} variant="h4">
                            Productos que pueden interesarte:
                        </Typography>
                    </Box>
                    <Box pb={8} display="flex" justifyContent="center">
                        {moreProducts && moreProducts.length > 0 ? (
                            <ProductsCarousel builds={moreProducts} />
                        ) : <LinearProgress color="primary" />}
                    </Box>
                </Box>
            </PageContent>
        </>
    );
};

export default ProductDetail;
