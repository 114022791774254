import React, { useState } from 'react';
import {
    Box, makeStyles, Typography, Hidden,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TablePagination, ButtonBase, Collapse,
    IconButton,
} from '@material-ui/core';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import colors from '../../theme/colors';
import cartHelper from '../../helpers/cartHelper';
import ModalOrderProductList from './ModalOrderProductList';

const useStyles = makeStyles(() => ({
    textCurrentBuy: {
        fontWeight: 700,
        fontSize: '16px',
    },
    orderItemLink: {
        fontWeight: 400,
        textDecoration: 'none',
        color: colors.black,
    },
    tableTitle: {
        fontWeight: 300,
        color: colors.blackGrey,
        fontSize: '16px',
    },
    labelRowsPerPage: {
        fontWeight: 300,
        color: colors.blackGrey,
        fontSize: '12px',
    },
    tableItem: {
        fontWeight: 400,
        fontSize: '14px',
        paddingTop: 13,
        paddingBottom: 13,
    },
    arrowIcon: {
        fontSize: '16px',
    },
}));

const ORDER_TYPES_DICTIONARY = {
    rma: {
        title: 'En proceso de garantía',
        isCollapsibleOpen: true,
        dateTitle: 'Inicio',
    },
    cancelled: {
        title: 'Compras canceladas',
        isCollapsibleOpen: false,
        dateTitle: 'Fecha',
    },
};

const CollapsibleOrders = ({ orders, ordersType }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [modalOrderProductListOpen, setModalOrderProductListOpen] = useState(false);

    const toggleModalOrderProductList = () => {
        setModalOrderProductListOpen(!modalOrderProductListOpen);
    };
    const handleChangePage = (e, newPage) => { setPage(newPage); };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredOrders = orders.filter((order) => order.status === ordersType);
    const [openCollapsible, setOpenCollapsible] = useState(
        ORDER_TYPES_DICTIONARY[ordersType].isCollapsibleOpen,
    );

    return (
        <>
            {filteredOrders.length
                ? (
                    <Box pt={3}>
                        <Box display="flex" pb={2}>
                            <Typography className={classes.textCurrentBuy}>
                                {ORDER_TYPES_DICTIONARY[ordersType].title}
                            </Typography>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapsible(!openCollapsible)}>
                                {openCollapsible
                                    ? <KeyboardArrowUpIcon className={classes.arrowIcon} />
                                    : <KeyboardArrowDownIcon className={classes.arrowIcon} />}
                            </IconButton>
                        </Box>
                        <Collapse in={openCollapsible} timeout="auto" unmountOnExit>
                            <TableContainer in={openCollapsible} timeout="auto" unmountOnExit>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none" className={classes.tableTitle}>
                                                Nro. de compras
                                            </TableCell>
                                            <TableCell className={classes.tableTitle}>
                                                Total
                                            </TableCell>
                                            <TableCell className={classes.tableTitle}>
                                                Productos
                                            </TableCell>
                                            <TableCell className={classes.tableTitle}>
                                                {ORDER_TYPES_DICTIONARY[ordersType].dateTitle}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredOrders
                                            .slice(page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage)
                                            .map((row) => (
                                                <TableRow key={row.code}>
                                                    <TableCell
                                                        className={classes.tableItem}
                                                        style={{ paddingRight: 12 }}
                                                        padding="none"
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.code}
                                                    </TableCell>
                                                    <TableCell className={classes.tableItem}>
                                                        {cartHelper.formatPrice(row.totalPrice)}
                                                    </TableCell>
                                                    <TableCell className={classes.tableItem}>
                                                        <Typography
                                                            component={ButtonBase}
                                                            onClick={toggleModalOrderProductList}
                                                            variant="subtitle1"
                                                            className={classes.orderItemLink}
                                                        >
                                                            VER DETALLE
                                                        </Typography>
                                                        <ModalOrderProductList
                                                            order={row}
                                                            openModal={modalOrderProductListOpen}
                                                            toggleModal={
                                                                toggleModalOrderProductList
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tableItem}>
                                                        {moment(row.statusDates[ordersType], false).format('DD/MM/YYYY')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Hidden xsDown>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    labelRowsPerPage={(
                                        <Box className={classes.labelRowsPerPage}>
                                            Resultados por página
                                        </Box>
                                    )}
                                    labelDisplayedRows={(row) => (
                                        <Box flexGrow={1} className={classes.labelRowsPerPage} justifyContent="flex-end">
                                            {row.from}
                                            {' - '}
                                            {row.to}
                                            {' de '}
                                            {row.count}
                                        </Box>
                                    )}
                                    component="div"
                                    count={filteredOrders.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    SelectProps={{
                                        style: {
                                            fontWeight: 300,
                                            color: colors.blackGrey,
                                            fontSize: '12px',
                                        },
                                    }}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                        style: { color: colors.blackGrey },
                                        autoid: 'pagination-button-next-collector',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                        style: { color: colors.blackGrey },
                                        autoid: 'pagination-button-previous-collector',
                                    }}
                                />
                            </Hidden>
                        </Collapse>
                    </Box>
                )
                : null}
        </>
    );
};

export default CollapsibleOrders;
