import React, { useContext } from 'react';
import {
    Box, Grid, Typography, Divider,
} from '@material-ui/core';

import useStyles from './useStyles';
import { UserContext } from '../../../contexts';
import cartHelper from '../../../helpers/cartHelper';
import paymentHelper from '../../../helpers/paymentHelper';
import proBuildHelper from '../../../helpers/proBuildHelper';

const Details = ({ showDetails, selectedPaymentMode }) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);
    const products = proBuildHelper.formatProductsWithBuilds(state.user?.cart?.items || []);

    const getPriceToShow = (item) => cartHelper.formatPrice(
        selectedPaymentMode === paymentHelper.paymentModeConstants.CARD
            ? item.price.list
            : item.price.special,
    );

    if (!showDetails) return null;

    return (
        <Box mb={2}>
            {products.map((item, i) => (
                <Box mb={3} key={item.id}>
                    <Box mb={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <img
                                    src={item.image}
                                    alt={item.name}
                                    className={classes.itemPhoto}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.itemDescription} variant="subtitle2">
                                    {item.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <Typography className={classes.itemPrice} variant="h6">
                                        {getPriceToShow(item)}
                                    </Typography>
                                    <Typography className={classes.itemQuantity} variant="subtitle1">
                                        {` (x${item.quantity})`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {state.user.cart.items.length !== i + 1 && (
                        <Divider />
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default Details;
