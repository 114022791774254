function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function separateText(text) {
    return text.replaceAll('_', ' ');
}

const dictionary = [
    { key: 'watts', value: 'Watts' },
    { key: 'capacity', value: 'Capacidad' },
    { key: 'quantity', value: 'Cantidad' },
    { key: 'stick_type', value: 'Tipo de ranura' },
    { key: 'cl', value: 'Latencia CL' },
    { key: 'clock_frequency', value: 'Frecuencia de reloj' },
    { key: 'mother_form_factor', value: 'Form factor del Mother' },
    { key: 'width', value: 'Ancho' },
    { key: 'fan_slots_120', value: 'Ranuras ventilador 120' },
    { key: 'fan_slots_140', value: 'Ranuras ventilador 140' },
    { key: 'fan_slots_240', value: 'Ranuras ventilador 240' },
    { key: 'fan_slots_280', value: 'Ranuras ventilador 280' },
    { key: 'fan_slots_360', value: 'Ranuras ventilador 360' },
    { key: 'radiator_support', value: 'Soporte para radiador' },
    { key: 'brand', value: 'Marca' },
    { key: 'socket', value: 'Socket' },
    { key: 'sockets', value: 'Sockets compatibles' },
    { key: 'has_cooler', value: 'Cooler integrado' },
    { key: 'igpu', value: 'Grafica integrada' },
    { key: 'power', value: 'Fuente' },
    { key: 'case_radiator', value: 'Requiere radiador en el gabinete' },
    { key: 'case_radiator_width', value: 'Ancho del radiador en el gabinete' },
    { key: 'interface', value: 'Interfaz' },
    { key: 'ram_stick_type', value: 'Tipo de memoria RAM' },
    { key: 'ram_slots', value: 'Cantidad de ranuras para RAM' },
    { key: 'pci_express_1x_slots', value: 'Ranuras PCI Express 1x' },
    { key: 'pci_express_2x_slots', value: 'Ranuras PCI Express 2x' },
    { key: 'pci_express_4x_slots', value: 'Ranuras PCI Express 4x' },
    { key: 'pci_express_8x_slots', value: 'Ranuras PCI Express 8x' },
    { key: 'pci_express_16x_slots', value: 'Ranuras PCI Express 16x' },
    { key: 'sata3_slots', value: 'Ranuras SATA3' },
    { key: 'm2_slots', value: 'Ranuras M2' },
    { key: 'cpu_socket', value: 'Socket CPU' },
    { key: 'form_factor', value: 'Form Factor' },
    { key: 'watts_supported', value: 'Watts Soportados' },
    { key: 'connectivity', value: 'Conectividad' },
    { key: 'keyboard', value: 'Teclado' },
    { key: 'mouse', value: 'Mouse' },
    { key: 'operativeSystem', value: 'Sistema Operativo' },
    { key: 'opertiveSystem', value: 'Sistema Operativo' },
    { key: 'ports', value: 'Puertos' },
    { key: 'monitor', value: 'Monitor' },
    { key: 'cpu_brand', value: 'Marca CPU' },
    { key: 'connections', value: 'Conexiones' },
    { key: 'inches', value: 'Pulgadas' },
    { key: 'refresh_rate', value: 'Tasa de Refresco' },
    { key: 'resolution', value: 'Resolución' },
    { key: 'video_output', value: 'Salida de video' },
];
function getTitle(textOrigin) {
    let textNew = '';
    dictionary.forEach((item) => {
        if (item.key === textOrigin) {
            textNew = item.value;
        }
    });
    return textNew || separateText(capitalizeFirstLetter(textOrigin));
}

function cleanDescription(description) {
    if (typeof description === 'string') {
        return description.replace('<p>', '').replace('</p>', '');
    }
    return description;
}

const textHelpers = {
    capitalizeFirstLetter,
    getTitle,
    cleanDescription,
};

export default textHelpers;
