import React from 'react';
import { Box } from '@material-ui/core';
import { StyledTitle, StyledTypography } from '../components/TextBlock';

import { accountDeleteRequest } from './constants';

const FaqDeleteAccount = () => (
    <div id="faq-delete-account">
        <StyledTitle>
            Preguntas Frecuentes - Derecho de Supresión (Eliminar Cuenta)
        </StyledTitle>
        <Box sx={{ ml: '20px' }}>
            <StyledTitle>
                Derecho de Supresión de Datos Personales
            </StyledTitle>
            <StyledTypography>
                De acuerdo con la Ley 25.326 de Protección de Datos Personales, tenés
                derecho a solicitar la supresión de tus datos personales cuando ya
                no sean necesarios para los fines por los cuales fueron recolectados.
                Este derecho implica la eliminación de tu cuenta y toda la
                información personal asociada.
            </StyledTypography>
            <StyledTypography>
                Si deseás ejercer tu derecho de supresión y eliminar tu cuenta,
                podés encontrar los pasos detallados para realizar esta solicitud
                en la sección de&nbsp;
                <strong>Eliminar Cuenta.</strong>
            </StyledTypography>
            <br />
            <StyledTitle>
                ¿Qué sucede una vez que solicito la eliminación?
            </StyledTitle>
            <ul>
                {
                    accountDeleteRequest.map((condition) => (
                        <li key={condition}>{condition}</li>
                    ))
                }
            </ul>
            <StyledTypography>
                <strong>Importante:</strong>
                &nbsp;Es posible que ciertos datos sean retenidos para cumplir
                con obligaciones legales o contractuales.
            </StyledTypography>
            <StyledTypography>
                Recibirás una notificación cuando tu solicitud haya sido procesada.
            </StyledTypography>
        </Box>
    </div>
);

export default FaqDeleteAccount;
