import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Box,
    Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import colors from '../../../../../theme/colors';
import GDTheme from '../../../../../theme/GDTheme';
import { UserContext } from '../../../../../contexts';
import paymentHelper from '../../../../../helpers/paymentHelper';
import cartHelper from '../../../../../helpers/cartHelper';
import { isSubsidizedShippingPrice } from '../../../../../data/constants';

const useStyles = makeStyles((theme) => ({
    transferDataContainer: {
        background: colors.grey,
        [theme.breakpoints.up('md')]: {
            width: 543,
        },
        padding: 20,
    },
    transferDataTitle: { letterSpacing: 0, marginBottom: 6 },
    transferDataSubTitle: { fontWeight: 700 },
    transferDataText: { fontWeight: 400, marginBottom: 12 },
    cbuCopyIcon: {
        marginLeft: 8,
        height: 14,
        width: 18,
    },
    clickable: { cursor: 'pointer' },

    stepChangeButton: {
        width: 129,
        height: 34,
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
    confirmPaymentButton: {
        width: 164,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

const BankTransferPaymentSubStep = ({
    goToSelectPaymentSubStep,
    selectedPaymentMode,
    generateOrder,
    shippingCost,
}) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const CBU = '0070004720000017884808';
    const ALIAS = 'GamerFactory.ar';
    const COMPANY_NAME = 'GAMER FACTORY SRL';
    const ACCOUNT_NUMBER = '0017884-8 004-0';
    const CUIT = '30-71722200-4';

    const handleClick = () => {
        const paymentData = {
            amount: cartHelper.getTotalPrice(
                state.user?.cart,
                paymentHelper.paymentModeConstants.BANK_TRANSFER,
                shippingCost,
            ).toString(),
            method: selectedPaymentMode,
        };
        generateOrder(paymentData);
    };

    return (
        <>
            <Grid container className={classes.transferDataContainer}>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.transferDataTitle}>
                        Datos de la cuenta:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} pb={2}>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        CBU
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        className={`${classes.transferDataText} ${classes.clickable}`}
                        onClick={() => {
                            navigator.clipboard.writeText(CBU);
                            enqueueSnackbar('Texto copiado al portapapeles', { variant: 'success' });
                        }}
                    >
                        {CBU}
                        <FileCopyOutlinedIcon className={classes.cbuCopyIcon} />
                    </Typography>

                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        Razón Social
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {COMPANY_NAME}
                    </Typography>

                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        CUIT
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {CUIT}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        Alias
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {ALIAS}
                    </Typography>

                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        Numero de cuenta
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {ACCOUNT_NUMBER}
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.actionButtons} flexGrow={1} m={2} mt={5}>
                <Button
                    variant="outlined"
                    className={classes.stepChangeButton}
                    onClick={goToSelectPaymentSubStep}
                >
                    Anterior
                </Button>
                <Button
                    variant="contained"
                    className={`${classes.confirmPaymentButton} ${classes.stepChangeButton}`}
                    onClick={handleClick}
                >
                    Finalizar Compra
                </Button>
            </Box>
        </>
    );
};

export default BankTransferPaymentSubStep;
