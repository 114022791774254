import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    ButtonBase,
    Divider,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core';

import CurrentOrdersStatus from './CurrentOrdersStatus';
import ModalOrderProductList from './ModalOrderProductList';

import cartHelper from '../../helpers/cartHelper';

import colors from '../../theme/colors';

import { SHIPPING_TYPES } from '../../common/constants';

const useStyles = makeStyles(() => ({
    divider: {
        backgroundColor: colors.black,
    },
    tableTitle: {
        fontWeight: 300,
        color: colors.blackGrey,
    },
    orderItem: {
        fontWeight: 400,
        textDecoration: 'none',
    },
    dividerSpacing: {
        marginTop: 16,
    },
    dividerSpacingLink: {
        marginTop: 15,
    },
}));

const OrderDetails = ({ order, showTitleOnDesktop }) => {
    const classes = useStyles();
    const [modalOrderProductListOpen, setModalOrderProductListOpen] = useState(false);

    const toggleModalOrderProductList = () => {
        setModalOrderProductListOpen(!modalOrderProductListOpen);
    };

    const isPickupAtWarehouse = order.shippingData
        ?.shippingType === SHIPPING_TYPES.pickupAtWarehouse;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="row">
                        <Grid item xs={12} sm={12} md={5}>
                            {
                                showTitleOnDesktop
                                    ? (
                                        <>
                                            <Box pt={2} pb={{ xs: 0, sm: 1 }}>
                                                <Typography className={classes.tableTitle} variant="h6">Nro de compra</Typography>
                                            </Box>
                                            <Hidden xsDown>
                                                <Divider />
                                            </Hidden>
                                        </ >
                                    )
                                    : (
                                        <>
                                            <Hidden mdUp>
                                                <Box pt={2} pb={{ xs: 0, sm: 1 }}>
                                                    <Typography className={classes.tableTitle} variant="h6">Nro de compra</Typography>
                                                </Box>
                                                <Hidden xsDown>
                                                    <Divider />
                                                </Hidden>
                                            </Hidden>
                                        </ >
                                    )
                            }

                            <Box mt={{ xs: 2, md: 3 }}>
                                <Typography variant="subtitle1" className={classes.orderItem}>
                                    {order.code}
                                </Typography>
                            </Box>
                            <Hidden smDown>
                                <Divider className={classes.dividerSpacing} />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {
                                showTitleOnDesktop
                                    ? (
                                        <>
                                            <Box pt={2} pb={{ xs: 0, md: 1 }}>
                                                <Typography className={classes.tableTitle} variant="h6">Total</Typography>
                                            </Box>
                                            <Hidden smDown>
                                                <Divider />
                                            </Hidden>
                                        </ >
                                    )
                                    : (
                                        <Hidden mdUp>
                                            <Box pt={2} pb={{ xs: 0, md: 1 }}>
                                                <Typography className={classes.tableTitle} variant="h6">Total</Typography>
                                            </Box>
                                            <Hidden smDown>
                                                <Divider />
                                            </Hidden>
                                        </Hidden>
                                    )
                            }

                            <Box mt={{ sm: 1, md: 3 }}>
                                <Typography variant="subtitle1" className={classes.orderItem}>
                                    {cartHelper.formatPrice(order.totalPrice)}
                                </Typography>
                            </Box>
                            <Hidden smDown>
                                <Divider className={classes.dividerSpacing} />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {
                                showTitleOnDesktop
                                    ? (
                                        <>
                                            <Box pt={2} pb={{ xs: 0, md: 1 }}>
                                                <Typography className={classes.tableTitle} variant="h6">Productos</Typography>
                                            </Box>
                                            <Hidden smDown>
                                                <Divider />
                                            </Hidden>
                                        </ >
                                    )
                                    : (
                                        <Hidden mdUp>
                                            <Box pt={2} pb={{ xs: 0, md: 1 }}>
                                                <Typography className={classes.tableTitle} variant="h6">Productos</Typography>
                                            </Box>
                                            <Hidden smDown>
                                                <Divider />
                                            </Hidden>
                                        </Hidden>
                                    )

                            }

                            <Box mt={{ sm: 1, md: 3 }}>
                                <Typography component={ButtonBase} onClick={toggleModalOrderProductList} variant="subtitle1" className={classes.orderItem}>
                                    VER DETALLE
                                </Typography>
                                <ModalOrderProductList
                                    order={order}
                                    openModal={modalOrderProductListOpen}
                                    toggleModal={toggleModalOrderProductList}
                                />
                            </Box>
                            <Hidden smDown>
                                <Divider className={classes.dividerSpacingLink} />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box pt={2} pb={2} display="flex" justifyContent="center" flexDirection="column">
                                <Typography className={classes.tableTitle} variant="h6">Datos de envío</Typography>
                                <Box pb={{ xs: 0, sm: 2 }}>
                                    <Typography variant="subtitle1">
                                        {isPickupAtWarehouse ? 'Retiro en depósito (CABA - Microcentro)' : order.completeShippingAddress}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            showTitleOnDesktop
                                ? (
                                    <>
                                        <Box pt={2} pb={{ xs: 0, sm: 1 }}>
                                            <Typography className={classes.tableTitle} variant="h6">Seguimiento</Typography>
                                        </Box>
                                        <Hidden xsDown>
                                            <Divider />
                                        </Hidden>
                                    </ >
                                )
                                : (
                                    <Hidden mdUp>
                                        <Box pt={2} pb={{ xs: 0, sm: 1 }}>
                                            <Typography className={classes.tableTitle} variant="h6">Seguimiento</Typography>
                                        </Box>
                                        <Hidden xsDown>
                                            <Divider />
                                        </Hidden>
                                    </Hidden>
                                )
                        }
                        <CurrentOrdersStatus
                            status={order.status ?? ''}
                            statusDates={order.statusDates ?? {}}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </Box>
    );
};

export default OrderDetails;
