/* eslint-disable jsx-a11y/control-has-associated-label,react/jsx-no-duplicate-props */
import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import {
    Box,
    Button, FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    NativeSelect,
    TextField,
    Typography,
    IconButton,
} from '@material-ui/core';
import Payment from 'payment';
import Popover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
    CreditCard, AssignmentInd, VpnKey,
} from '@material-ui/icons';
import colors from '../../../../../theme/colors';
import { useMercadopago } from './useMercadopago';
import LoadingButton from '../../../../LoadingButton';
import { UserContext } from '../../../../../contexts';
import paymentHelper from '../../../../../helpers/paymentHelper';
import cartHelper from '../../../../../helpers/cartHelper';
import { isSubsidizedShippingPrice } from '../../../../../data/constants';

const tarjetaNaranjaPattern = 589562;

Payment.addToCardArray({
    cvcLength: [3],
    format: /(\d{1,4})/g,
    length: [16],
    luhn: false,
    pattern: [tarjetaNaranjaPattern],
    type: 'naranja',
});

const cardWrapperStyle = (cardNumber) => {
    let styles = { transform: 'scale(0.75)' };
    if (String(cardNumber).startsWith(tarjetaNaranjaPattern)) {
        styles = {
            ...styles,
            '& .rccs__card.rccs__card--naranja .rccs__card__background': {
                background: 'linear-gradient(25deg, #e54400, #d83c01) !important',
            },
        };
    }
    return styles;
};

const useStyles = makeStyles((theme) => ({
    leftSideOfForm: {
        [theme.breakpoints.up('md')]: {
            marginTop: -20,
        },
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: 'auto',
    },
    showOnlyOnMobile: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    showOnlyOnDesktop: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    styleBottomHalf: {
        marginTop: '-12px',
        [theme.breakpoints.down('md')]: {
            marginTop: '4px',
        },
    },
    formItem: {
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 0,
        },
    },
    buttonContainer: {
        paddingTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    iconMoreInformation: {
        marginLeft: '6px',
        height: 14,
        width: 14,
    },
    infoPaymentContainer: {
        backgroundColor: colors.white,
        padding: '9px 29px 10px 10px',
        width: '200px',
    },
    titleInfoPayment: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: colors.blackGrey,
    },
    identificationTypeInput: {
        margin: '4px 0px 16px',
        [theme.breakpoints.down('md')]: {
            margin: '20px 0px 16px',
        },
    },
    emailInput: {
        margin: '4px 0px 16px',
        [theme.breakpoints.down('md')]: {
            margin: '0px 0px 16px',
        },
    },
}));

const onlyNumbers = (value) => value.replace(/\D/g, '');

const CardsPaymentSubStep = ({
    generateOrder, goToSelectPaymentSubStep, selectedPaymentMode, shippingCost,
}) => {
    const classes = useStyles();
    const { loading: mpLoading, setLoading: setMpLoading, mercadopago } = useMercadopago();
    const [state, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-Popover',
    });

    const [loading, setLoading] = useState(false);
    const [formMounted, setFormMounted] = useState(false);
    const [isValidCardNumber, setIsValidCardNumber] = useState(false);
    const [cardNumberHasError, setCardNumberHasError] = useState(false);
    const [maxCreditCardNumberLength, setMaxCreditCardNumberLength] = useState(16);
    const [securityCode, setSecurityCode] = useState('');
    const [cardExpiration, setCardExpiration] = useState('');
    const [cardExpirationMonth, setCardExpirationMonth] = useState('');
    const [cardExpirationYear, setCardExpirationYear] = useState('');
    const [cardholderEmail, setCardholderEmail] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [focused, setFocused] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [coupon, setCoupon] = useState(state?.user?.cart?.appliedCoupon?.code || null);
    const [cardForm, setCardForm] = useState(null);

    const getAmount = useCallback(() => (
        selectedPaymentMode === paymentHelper.paymentModeConstants.CARD
            ? cartHelper.getTotalPrice(
                state.user?.cart,
                paymentHelper.paymentModeConstants.CARD,
                shippingCost,
            ).toString()
            : cartHelper.getTotalPrice(
                state.user?.cart,
                paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT,
                shippingCost,
            ).toString()),
    [
        selectedPaymentMode, shippingCost, state,
    ]);

    useEffect(() => {
        setCardExpiration(
            `${cardExpirationMonth.length === 1 ? '0' : ''}${cardExpirationMonth}/${cardExpirationYear}`,
        );
    }, [cardExpirationMonth, cardExpirationYear]);

    useEffect(() => {
        setCardholderEmail(state.user.email);
    }, [state.user.email]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isCreditCardValid', (value) => isValidCardNumber);
        return () => {
            ValidatorForm.removeValidationRule('isCreditCardValid');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const validation = focused === 'number' && cardNumber.length >= maxCreditCardNumberLength && !isValidCardNumber;
        const validation2 = focused !== 'number' && cardNumber.length > 0 && !isValidCardNumber;
        setCardNumberHasError(validation || validation2);
    }, [focused, cardNumber, isValidCardNumber, maxCreditCardNumberLength]);

    const handleChangeCardNumber = (e) => {
        const { value } = e.target;
        setCardNumber(onlyNumbers(value));
        if (value.length >= 6) {
            const bin = value.substring(0, 6);
            mercadopago.getInstallments({
                bin,
                amount: getAmount(),
            });
        }
    };
    const handleChangeCardholderName = (e) => {
        setCardholderName(e.target.value.replace(/[^a-zA-Z ]/g, '').toUpperCase());
    };
    const handleChangeIdentificationNumber = (e) => {
        if (e.target.value.length <= 9) setIdentificationNumber(onlyNumbers(e.target.value));
    };
    const handleChangeCardholderEmail = (e) => { setCardholderEmail(e.target.value); };
    const handleChangeSecurityCode = (e) => { setSecurityCode(e.target.value); };
    const handleChangeExpirityMonth = (e) => {
        if (e.target.value.length <= 2) setCardExpirationMonth(onlyNumbers(e.target.value));
    };
    const handleChangeExpirityYear = (e) => {
        if (e.target.value.length <= 4) setCardExpirationYear(onlyNumbers(e.target.value));
    };

    const handleBlur = () => { setFocused(''); };

    const expirityHasError = () => {
        if (cardExpiration.length < 5) return false;
        const month = cardExpirationMonth;
        if (Number.isNaN(month) || month < 1 || month > 12) return true;
        const year = cardExpirationYear.length === 2 ? `20${cardExpirationYear}` : cardExpirationYear;
        if (Number.isNaN(year)) return true;
        const now = new Date();
        return now > new Date(parseInt(year, 10), parseInt(month, 10), 0);
    };

    const emailHasError = () => {
        const isValidEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return cardholderEmail.length > 0 && !isValidEmail(cardholderEmail);
    };

    useEffect(() => {
        if (
            state?.user?.cart?.appliedCoupon?.code
            && coupon !== state?.user?.cart?.appliedCoupon?.code
            && cardForm
        ) {
            setCoupon(state.user.cart.appliedCoupon.code);
            cardForm.unmount();
        }
    }, [coupon, state?.user?.cart?.appliedCoupon, cardForm]);

    useEffect(() => {
        if (!mpLoading && mercadopago && !formMounted && !cardForm) {
            setCardForm(mercadopago.cardForm({
                amount: getAmount(),
                autoMount: true,
                form: {
                    id: 'form-checkout',
                    cardholderName: { id: 'form-checkout__cardholderName' },
                    cardholderEmail: { id: 'form-checkout__cardholderEmail' },
                    cardNumber: { id: 'form-checkout__cardNumber' },
                    cardExpirationMonth: { id: 'form-checkout__cardExpirationMonth' },
                    cardExpirationYear: { id: 'form-checkout__cardExpirationYear' },
                    securityCode: { id: 'form-checkout__securityCode' },
                    installments: { id: 'form-checkout__installments' },
                    identificationType: { id: 'form-checkout__identificationType' },
                    identificationNumber: { id: 'form-checkout__identificationNumber' },
                    issuer: { id: 'form-checkout__issuer' },
                },
                callbacks: {
                    onFormUnmounted: (error) => {
                        if (error) {
                            console.error('Form Mounted handling error: ', error);
                            enqueueSnackbar('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos. Si el problema persiste, contacte a su entidad bancaria o pruebe con otra tarjeta.');
                        } else {
                            setFormMounted(false);
                            setCardForm(null);
                        }
                    },
                    onFormMounted: (error) => {
                        if (error) {
                            console.error('Form Mounted handling error: ', error);
                            enqueueSnackbar('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos. Si el problema persiste, contacte a su entidad bancaria o pruebe con otra tarjeta.');
                        } else {
                            setFormMounted(true);
                            setMpLoading(false);
                        }
                    },
                    onInstallmentsReceived: (error, installments) => {
                        if (error) {
                            enqueueSnackbar('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos.');
                        } else {
                            document.getElementById('form-checkout__installments').options.length = 0;

                            installments.payer_costs.forEach((payerCost) => {
                                const opt = document.createElement('option');
                                opt.text = payerCost.recommended_message;
                                opt.value = payerCost.installments;

                                if (opt.value !== '1'
                                    && Number(opt.value) >= 3
                                    && Number(opt.value) <= 6
                                    && selectedPaymentMode
                                    === paymentHelper.paymentModeConstants.CARD) {
                                    document.getElementById('form-checkout__installments').appendChild(opt);
                                }
                                if (opt.value === '1'
                                    && selectedPaymentMode
                                    === paymentHelper
                                        .paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT) {
                                    document.getElementById('form-checkout__installments').appendChild(opt);
                                }
                            });
                        }
                    },
                },
            }));
        }
    }, [
        mercadopago, formMounted, mpLoading, enqueueSnackbar,
        cardForm, state.user.cart?.totalPrice, shippingCost,
        selectedPaymentMode, getAmount, setMpLoading, coupon,
    ]);

    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cardNumberHasError || expirityHasError() || emailHasError()) return;
        setLoading(true);
        setIsFormDisabled(true);
        const tokenForMPCardFormData = await cardForm.createCardToken();
        const mpCardFormData = cardForm.getCardFormData();
        mpCardFormData.token = tokenForMPCardFormData.token;

        const paymentData = {
            amount: getAmount(),
            method: selectedPaymentMode,
            mpCardFormData,
        };
        generateOrder(paymentData).finally(() => {
            setLoading(false);
            setIsFormDisabled(false);
        });
    };

    return (
        <>
            {mpLoading ? (
                <Box mt={3}>
                    <LinearProgress color="primary" />
                    <Typography>
                        Preparando el formulario de pago...
                    </Typography>
                </Box>
            ) : (
                <>
                    <form id="form-checkout" autoComplete="off">
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4} className={classes.leftSideOfForm}>
                                <Box className={classes.cardContainer}>
                                    <Box sx={cardWrapperStyle(cardNumber)}>
                                        <Cards
                                            cvc={securityCode}
                                            expiry={cardExpiration}
                                            focused={focused}
                                            name={cardholderName}
                                            number={cardNumber}
                                            locale={{ valid: 'fecha exp.' }}
                                            placeholders={{ name: 'NOMBRE Y APELLIDO' }}
                                            callback={({ maxLength }, isValid) => {
                                                setMaxCreditCardNumberLength(maxLength);
                                                setIsValidCardNumber(isValid);
                                            }}
                                            disabled={isFormDisabled}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={7}>
                                        <TextField
                                            type="text"
                                            value={cardNumber}
                                            onChange={(e) => handleChangeCardNumber(e)}
                                            onFocus={() => setFocused('number')}
                                            onBlur={handleBlur}
                                            label="Número de la tarjeta"
                                            fullWidth
                                            id="form-checkout__cardNumber"
                                            error={cardNumberHasError}
                                            helperText={cardNumberHasError ? 'El número de la tarjeta es inválido' : ''}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start"><CreditCard /></InputAdornment>),
                                            }}
                                            inputProps={{ maxLength: maxCreditCardNumberLength }}
                                            required
                                            className={classes.formItem}
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={5} className={classes.showOnlyOnMobile}>
                                        <TextField
                                            type="text"
                                            value={securityCode}
                                            onChange={handleChangeSecurityCode}
                                            onFocus={() => setFocused('cvc')}
                                            label="Código de seguridad"
                                            fullWidth
                                            onBlur={handleBlur}
                                            id="form-checkout__securityCode"
                                            InputProps={{ startAdornment: (<InputAdornment position="start"><VpnKey /></InputAdornment>) }}
                                            required
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={5}>
                                        <Box display="flex" alignItems="flex-end">
                                            <TextField
                                                type="text"
                                                value={cardExpirationMonth}
                                                onFocus={() => setFocused('expiry')}
                                                onBlur={handleBlur}
                                                id="form-checkout__cardExpirationMonth"
                                                onChange={handleChangeExpirityMonth}
                                                label="Mes"
                                                placeholder="MM"
                                                error={expirityHasError()}
                                                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                                                required
                                                disabled={isFormDisabled}
                                            />
                                            <Typography variant="h3" style={{ marginRight: 10, marginLeft: 10 }}>
                                                /
                                            </Typography>
                                            <TextField
                                                type="text"
                                                value={cardExpirationYear}
                                                onFocus={() => setFocused('expiry')}
                                                onBlur={handleBlur}
                                                label="Año"
                                                placeholder="AA"
                                                id="form-checkout__cardExpirationYear"
                                                onChange={handleChangeExpirityYear}
                                                error={expirityHasError()}
                                                inputProps={{ maxLength: 4, style: { textAlign: 'center' } }}
                                                required
                                                disabled={isFormDisabled}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            value={cardholderName}
                                            onFocus={() => setFocused('name')}
                                            label="Titular de la tarjeta"
                                            onBlur={handleBlur}
                                            id="form-checkout__cardholderName"
                                            onChange={handleChangeCardholderName}
                                            InputProps={{ startAdornment: (<InputAdornment position="start"><AssignmentInd /></InputAdornment>) }}
                                            required
                                            inputProps={{ minLength: 3 }}
                                            className={classes.formItem}
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5} className={classes.showOnlyOnDesktop}>
                                        <TextField
                                            type="text"
                                            value={securityCode}
                                            onChange={handleChangeSecurityCode}
                                            onFocus={() => setFocused('cvc')}
                                            label="Código de seguridad"
                                            fullWidth
                                            onBlur={handleBlur}
                                            id="form-checkout__securityCode"
                                            InputProps={{ startAdornment: (<InputAdornment position="start"><VpnKey /></InputAdornment>) }}
                                            required
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4} className={classes.styleBottomHalf}>
                                <FormControl fullWidth className={classes.identificationTypeInput}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
                                        Tipo de documento
                                    </InputLabel>
                                    <NativeSelect
                                        inputProps={{
                                            id: 'form-checkout__identificationType',
                                            defaultValue: 'DNI',
                                        }}
                                        disabled={isFormDisabled}
                                    />
                                </FormControl>
                                <TextField
                                    value={identificationNumber}
                                    onChange={handleChangeIdentificationNumber}
                                    type="text"
                                    fullWidth
                                    label="Numero de documento"
                                    id="form-checkout__identificationNumber"
                                    required
                                    disabled={isFormDisabled}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ margin: '16px 0px 4px' }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8} className={classes.styleBottomHalf}>
                                <TextField
                                    value={cardholderEmail}
                                    onChange={handleChangeCardholderEmail}
                                    error={emailHasError()}
                                    type="email"
                                    fullWidth
                                    label="Email"
                                    id="form-checkout__cardholderEmail"
                                    disabled={isFormDisabled}
                                    className={classes.emailInput}
                                />
                                <FormControl fullWidth style={{ margin: '16px 0px 4px' }}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
                                        Cantidad de cuotas
                                        <IconButton
                                            aria-label="moreInformation"
                                            color="inherit"
                                            style={{ color: colors.blackGrey }}
                                            {...bindHover(popupState)}
                                        >
                                            <InfoIcon
                                                className={classes.iconMoreInformation}
                                                style={{ padding: 0 }}
                                            />
                                        </IconButton>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                            disableRestoreFocus
                                        >
                                            <Box
                                                className={classes.infoPaymentContainer}
                                            >
                                                <Typography variant="subtitle2" className={classes.titleInfoPayment}>
                                                    <strong>El precio especial</strong>
                                                    {' '}
                                                    aplica a pagos
                                                    con tarjeta de débito o crédito
                                                    en una cuota.
                                                </Typography>
                                            </Box>
                                        </Popover>
                                    </InputLabel>
                                    <NativeSelect
                                        inputProps={{
                                            id: 'form-checkout__installments',
                                        }}
                                        disabled={isFormDisabled}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <Box className={classes.buttonContainer}>
                                    <Button
                                        variant="outlined"
                                        onClick={goToSelectPaymentSubStep}
                                    >
                                        Volver
                                    </Button>
                                    <Box ml={2}>
                                        <LoadingButton
                                            onClick={(e) => handleSubmit(e)}
                                            loading={loading}
                                            variant="contained"
                                        >
                                            Pagar
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Grid>
                            <select style={{ display: 'none' }} name="issuer" id="form-checkout__issuer" />
                        </Grid>
                    </form>
                </>
            )}
        </>
    );
};

export default CardsPaymentSubStep;
