import React from 'react';
import {
    Box, Grid, Typography, CircularProgress,
} from '@material-ui/core';

import useStyles from './useStyles';
import cartHelper from '../../../helpers/cartHelper';
import { isSubsidizedShippingPrice } from '../../../data/constants';

const ShippingPriceFormatted = ({ shippingData }) => (
    <Typography variant="h6">
        {cartHelper.formatPrice(shippingData.price)}
    </Typography>
);

const ShippingCost = ({
    actualStep,
    shippingData,
    showShippingCosts,
    loadingShippingPrice,
}) => {
    const classes = useStyles();

    if (!showShippingCosts
        || isSubsidizedShippingPrice
        || !shippingData?.price
        || actualStep < 1
    ) return null;

    return (
        <Box mt={2} mb={2}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.products}>
                        Envío
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end">
                        {
                            loadingShippingPrice
                                ? <CircularProgress size={18} color="#000" />
                                : <ShippingPriceFormatted shippingData={shippingData} />
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShippingCost;
