import React, { useContext } from 'react';
import {
    Box,
    Button,
    makeStyles,
    Typography,
} from '@material-ui/core';
import noResults from '../easy-builder/images/noResults.svg';
import { ProductsContext } from '../../contexts/Products/context';

const useStyles = makeStyles(() => ({
    noResults: {
        margin: '10px 0px',
        textAlign: 'center',
    },
}));
const NoResultsMessage = ({ from, goToFirstStep }) => {
    const classes = useStyles();
    const { productsProBuilder, productsDispatch } = useContext(ProductsContext);
    const messages = {
        proBuilder: (
            <>
                <Typography variant="h4" className={classes.noResults}>
                    No hay productos compatibles con los seleccionados anteriormente.
                </Typography>
                <Typography variant="h4" className={classes.noResults}>
                    ¡Pero podés probar seleccionando otros componentes!
                </Typography>
            </>
        ),
        proBuilderOptional: (
            <>
                <Typography variant="h4" className={classes.noResults}>
                    ¡No tenemos en stock componentes que sean compatibles!
                </Typography>
                <Typography variant="h4" className={classes.noResults}>
                    Pero no te preocupes, este componente es opcional, ¡tu build va a
                    funcionar perfecto sin él!
                </Typography>
                <Typography variant="h4" className={classes.noResults}>
                    Podés avanzar al siguiente paso sin problemas
                </Typography>
            </>
        ),
        easyBuilder: (
            <Typography variant="h4" className={classes.noResults}>
                Lo sentimos. No encontramos ningún resultado que se adecúe a tus requisitos.
            </Typography>
        ),
        ecommerce: (
            <Typography variant="h4" className={classes.noResults}>
                No hay productos que coincidan con tu búsqueda.
            </Typography>
        ),
    };

    const cleanBuild = () => {
        productsDispatch({
            type: 'DELETE_BUILD',
        });
    };
    return (
        <Box mt={5} maxWidth="90%" display="flex" flexDirection="column" alignItems="center">
            <img src={noResults} alt="Sin resultados" />
            <Box mt={5}>
                {messages[from]}
            </Box>
            <Box display="flex" justifyContent="center" mt={5}>
                {(from !== 'ecommerce' && from !== 'proBuilderOptional') && (
                    <Button
                        variant="outlined"
                        onClick={from === 'easyBuilder' ? goToFirstStep : cleanBuild}
                    >
                        Volver a empezar
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default NoResultsMessage;
