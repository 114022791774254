import { ProductTypes } from '../helpers/types';
import cpuCcase from '../resources/pro-builder/images/case.svg';
import cooler from '../resources/pro-builder/images/cooler.svg';
import cpu from '../resources/pro-builder/images/cpu.svg';
import gpu from '../resources/pro-builder/images/gpu.svg';
import mother from '../resources/pro-builder/images/mother.svg';
import power from '../resources/pro-builder/images/power.svg';
import ram from '../resources/pro-builder/images/ram.svg';
import storage from '../resources/pro-builder/images/storage.svg';

const componentsTypes = [
    {
        title: 'Gabinetes',
        image: cpuCcase,
        icon: cpuCcase,
        type: ProductTypes.CASE,
    },
    {
        title: 'Microprocesadores',
        image: cpu,
        icon: cpu,
        type: ProductTypes.CPU,
    },
    {
        title: 'Placas de video',
        image: gpu,
        icon: gpu,
        type: ProductTypes.GPU,
    },
    {
        title: 'Motherboard',
        image: mother,
        icon: mother,
        type: ProductTypes.MOTHER,
    },
    {
        title: 'Fuentes',
        image: power,
        icon: power,
        type: ProductTypes.POWER,
    },
    {
        title: 'Memorias',
        image: ram,
        icon: ram,
        type: ProductTypes.RAM,
    },
    {
        title: 'Almacenamiento',
        subTypes: [
            {
                title: 'Discos internos',
                image: storage,
                icon: storage,
                type: ProductTypes.INTERNAL_STORAGE,
            },
            {
                title: 'Discos externos',
                image: storage,
                icon: storage,
                type: ProductTypes.EXTERNAL_STORAGE,
            },
        ],
    },
    {
        title: 'Refrigeración',
        subTypes: [
            {
                title: 'Cooler',
                image: cooler,
                icon: cooler,
                type: ProductTypes.CPU_COOLER,
            },
            {
                title: 'Fans',
                image: cooler,
                icon: cooler,
                type: ProductTypes.CASE_FAN,
            },
            {
                title: 'Pasta térmica',
                image: cooler,
                icon: cooler,
                type: ProductTypes.THERMAL_PASTE,
            },
        ],
    },
];

export default componentsTypes;
