import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
    Box,
    Grid,
    Hidden,
    Typography,
    Container,
} from '@material-ui/core';

import ProductFilters from './ProductFilters';
import ProductsList from './ProductsList';
import {
    getHelmetTitle,
    getHelmetDescription,
} from './utils';
import useProductsPage from './useProductsPage';
import { useProductsPageStyles } from './styles';
import FilterChip from './components/FilterChip';
import OrderSelect from './components/OrderSelect';
import MobileFiltersDropdownButton from './components/MobileFiltersDropdownButton';
import ChipSpecs from './components/ChipSpecs';
import MobileFilters from './components/MobileFilters';
import { offersRoute, productsRoute } from './constants';
import { helmetOffersPageConfig } from '../../data/seo';

const ProductsPage = ({ isOffersPage = false }) => {
    const classes = useProductsPageStyles();
    const location = useLocation();

    const {
        sort,
        price,
        params,
        checked,
        filterQ,
        isLoading,
        products,
        totalPages,
        selectedType,
        selectedPrice,
        currentPage,
        showMobileFilters,
        setSort,
        setPrice,
        setFilterQ,
        setChecked,
        handleSelect,
        setSelectedType,
        handleDeleteChip,
        setSelectedPrice,
        handleChangeCheck,
        handleChangePrice,
        handlePriceEntered,
        setShowMobileFilters,
        resetSelectedFilters,
        updateSelectedFilters,
        shouldResetSelectedFilters,
        shouldUpdateSelectedFilters,
        getProducts,
    } = useProductsPage(isOffersPage ? offersRoute : productsRoute);
    const pageTitle = isOffersPage ? helmetOffersPageConfig.title : getHelmetTitle(params);

    const productsFilters = { additionalFilters: isOffersPage ? { tag: 'oferta' } : {} };

    const getProductsWithFilters = () => getProducts(productsFilters);

    // Logic to handle url query and set all the filters and specs.
    // Page and sort are handled by getInitialPage and getInitialSort
    useEffect(() => {
        if (shouldResetSelectedFilters()) resetSelectedFilters();
        else if (shouldUpdateSelectedFilters()) updateSelectedFilters();
        else getProducts({ ...productsFilters, cleanProducts: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search, selectedType]);

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:title" content={pageTitle} />
                <meta
                    name="description"
                    content={
                        isOffersPage
                            ? helmetOffersPageConfig.description
                            : getHelmetDescription(params)
                    }
                />
                <meta
                    name="og:description"
                    content={
                        isOffersPage
                            ? helmetOffersPageConfig.description
                            : getHelmetDescription(params)
                    }
                />
            </Helmet>
            {
                showMobileFilters ? (
                    <MobileFilters
                        price={price}
                        route={productsRoute}
                        checked={checked}
                        filterQ={filterQ}
                        setPrice={setPrice}
                        setFilterQ={setFilterQ}
                        setChecked={setChecked}
                        selectedType={selectedType}
                        handleSelect={handleSelect}
                        selectedPrice={selectedPrice}
                        setSelectedType={setSelectedType}
                        handleDeleteChip={handleDeleteChip}
                        setSelectedPrice={setSelectedPrice}
                        handleChangeCheck={handleChangeCheck}
                        handleChangePrice={handleChangePrice}
                        handlePriceEntered={handlePriceEntered}
                        setShowMobileFilters={setShowMobileFilters}
                    />
                ) : (
                    <>
                        <MobileFiltersDropdownButton onClick={() => setShowMobileFilters(true)} />
                        <Container maxWidth="xl">
                            <Hidden mdUp>
                                {!_.isEmpty(checked) && (
                                    <Box mx={3} mb={2}>
                                        <FilterChip filterQ={filterQ} setFilterQ={setFilterQ} />
                                        <ChipSpecs
                                            checked={checked}
                                            handleDeleteChip={handleDeleteChip}
                                        />
                                    </Box>
                                )}
                                <Box className={classes.filtersContainer}>
                                    <Box ml={2}>
                                        <Typography variant="h5">
                                            {selectedType?.title ?? ' '}
                                        </Typography>
                                    </Box>
                                    <OrderSelect
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                </Box>
                            </Hidden>
                            <Hidden smDown>
                                <OrderSelect
                                    sort={sort}
                                    setSort={setSort}
                                />
                            </Hidden>
                            <Grid container spacing={3}>
                                <Grid item xs={false} md={3} lg={3} xl={2}>
                                    <Hidden smDown>
                                        <ProductFilters
                                            route={isOffersPage ? offersRoute : productsRoute}
                                            setSelectedType={setSelectedType}
                                            selectedType={selectedType}
                                            setPrice={setPrice}
                                            price={price}
                                            handleSelect={handleSelect}
                                            handleChangeCheck={handleChangeCheck}
                                            checked={checked}
                                            setChecked={setChecked}
                                            handleChangePrice={handleChangePrice}
                                            setSelectedPrice={setSelectedPrice}
                                            selectedPrice={selectedPrice}
                                            handlePriceEntered={handlePriceEntered}
                                            filterQ={filterQ}
                                            setFilterQ={setFilterQ}
                                            setShowMobileFilters={setShowMobileFilters}
                                            handleDeleteChip={handleDeleteChip}
                                        />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} md={9} lg={9} xl={10}>
                                    {(!showMobileFilters)
                                        && (
                                            <ProductsList
                                                products={products}
                                                totalPages={totalPages}
                                                getProducts={getProductsWithFilters}
                                                currentPage={currentPage}
                                                setSort={setSort}
                                                sort={sort}
                                                isLoading={isLoading}
                                            />
                                        )}
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                )
            }
        </>
    );
};

export default withRouter(ProductsPage);
